import Button from "@main-components/buttons";
import Card from "@main-components/card";
import DataGrid from "@main-components/data-grid";
import { DateField } from "@main-components/fields/date-field";
import TextField from "@main-components/fields/text-field";
import List from "@main-components/list";
import Empty from "@main-components/list/components/empty";
import useResourceCount from "@misc/utils/useResourceCount";
import * as React from "react";
import { ReferenceField, useTranslate } from "react-admin";
import { useSelector } from "react-redux";

export default function RealizationsWidget() {
  const translate = useTranslate();
  const { count } = useResourceCount("realizations");
  const dashboardTotal = useSelector((state: any) => {
    return state.admin.resources["dashboard-realizations"]?.list?.total;
  });

  return (
    <Card className="mb-5">
      <Card.Header>
        <i className="fal fa-images mr-2 " aria-hidden="true"></i>
        {translate("resources.realizations.titles.recent_realizations")}
      </Card.Header>
      <Card.Body>
        <List
          resource="dashboard-realizations"
          basePath="/realizations"
          hasCreate={true}
          hasEdit={false}
          hasList={true}
          hasShow={true}
          title=" "
          sort={{ field: "createdAt", order: "desc" }}
          bulkActionButtons={false}
          exporter={false}
          component={"div"}
          actions={false}
          mainActions={false}
          pagination={false}
          perPage={5}
          empty={
            <Empty
              hideActions
              resourceOverride="realizations"
              basePath="/realizations"
              subtitle=" "
              icon={
                <div>
                  <img
                    src="/img/illustrations/portfolio.svg"
                    className="w-100"
                  />
                </div>
              }
            />
          }
        >
          <DataGrid rowClick="show" resource="realizations">
            <DateField
              source="createdAt"
              label="resources.realizations.fields.createdAt"
              showTime
              sortable={false}
            />
            <TextField
              source="title"
              label="resources.realizations.fields.title"
            />

            <ReferenceField
              source="order"
              reference="orders"
              link={false}
              basePath="/orders"
            >
              <TextField source="orderId" />
            </ReferenceField>
          </DataGrid>
        </List>
        <div
          className={`mt-4  mb-4 ${
            dashboardTotal === 0
              ? "d-flex align-items-center justify-content-center"
              : ""
          }`}
        >
          <Button
            className="mr-1"
            label="resources.realizations.actions.add_realization"
            block={false}
            link
            to="/realizations?newRealization=true"
          >
            <span>
              <i className="fas fa-plus-circle mr-1" aria-hidden="true" />
            </span>
          </Button>

          {dashboardTotal > 0 && (
            <Button
              label={`resources.realizations.actions.see_all_realization`}
              labelParams={{ total: count }}
              block={false}
              link
              to="/realizations"
              variant="outline"
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
}
