import {useEditController, useGetOne, useNotify, useRefresh} from "react-admin";
import React from "react";

export function FormWrapper({id, children}) {
    const {data: website, loading} = useGetOne("website", id, {enabled: !!id})

    if (loading) return null

    if (!website) {
        return <div></div>
    }

    return <EditWrapper id={id}>{children}</EditWrapper>
}

const transformWebsiteData = (data) => {
    // Transform contactPerson.info.profilePicture

    if (data.contactPerson && data.contactPerson.info && data.contactPerson.info.profilePicture) {
        if (data.contactPerson.info.profilePicture.rawFile) {
            data.contactPerson.info.profilePicture = data.contactPerson.info.profilePicture.rawFile;
        }
    }

    // Transform branding.logo
    if (data.branding && data.branding.logo) {
        if (data.branding.logo.rawFile) {
            data.branding.logo = data.branding.logo.rawFile;
        }
    }

    // Transform header.headerImage
    if (data.header && data.header.headerImage) {
        if (data.header.headerImage.rawFile) {
            data.header.headerImage = data.header.headerImage.rawFile;
        }
    }

    if (data.realisations.gallery) {
        data.realisations.gallery = data.realisations.gallery
            ? data.realisations.gallery.map((file) => {
                if (file.url.rawFile) {
                    file.url = file.url.rawFile;
                    file.description = ""
                }

                return file;
            })
            : [];
    }

    return data;
};

function EditWrapper({id, children}) {
    const refresh = useRefresh();
    const notify = useNotify();
    const controllerProps = useEditController({
        id: id,
        resource: "website",
        basePath: "/website",
        transform: transformWebsiteData,
        undoable: false,
        onSuccess: () => {
            notify("resources.website.notifications.updated", "info",);
            refresh();
        },
    })

    return (
        < >
            {React.cloneElement(children, {controllerProps: controllerProps})}
        </>
    )
}