import {useAuthenticated, useGetIdentity, useTranslate} from "react-admin";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import {WebsiteForm} from "@main/public-website/components/website-form";
import {FormWrapper} from "@main/public-website/components/form-wrapper";

const useStyles = makeStyles({
    label: {width: "10em", display: "inline-block"},
    button: {margin: "1em"},
});
export default function PublicWebsite() {
    useAuthenticated();

    const {loaded, loading, identity, error} = useGetIdentity();

    if (error) return null

    if (loading) return null

    if (!loaded || !identity) return null;


    return (
        <PublicWebsiteContent id={identity.id}/>
    )
}

function PublicWebsiteContent({id}) {
    const translate = useTranslate();

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2 mb-2">
                <h1>{translate("general.website")}</h1>
            </div>

            <FormWrapper id={id}>
                <WebsiteForm/>
            </FormWrapper>
        </>
    )
}

