import Card from "@main-components/card";
import FormInput from "@main-components/forms/form-input";
import TextInput from "@main-components/inputs/text-input";
import {required, useTranslate} from "react-admin";
import React from "react";
import RichTextInput from "@main-components/inputs/rich-text-input";

export function CompanyInfoSection() {
    const translate = useTranslate()

    return (

        <div className={"row"}>
            <div className={"col-12 col-lg-6"}>
                <Card className="mb-5">
                    <Card.Header>
                        <i
                            className="fas fa-question-circle mr-2"
                            aria-hidden="true"
                        />
                        {translate("general.generalInfo")}
                    </Card.Header>
                    <Card.Body>
                        {/*@ts-ignore*/}
                        <FormInput
                            input={<TextInput
                                source="companyInfo.companyName"
                                label="resources.website.fields.companyInfo.companyName"
                                validate={required()}
                            />}
                        />


                        {/*@ts-ignore*/}
                        <FormInput
                            input={<TextInput
                                source="companyInfo.street"
                                label="resources.website.fields.companyInfo.street"
                                validate={required()}
                            />}
                        />

                        {/*@ts-ignore*/}
                        <FormInput
                            input={<TextInput
                                source="companyInfo.zip"
                                label="resources.website.fields.companyInfo.zip"
                            />}
                        />

                        {/*@ts-ignore*/}
                        <FormInput
                            input={<TextInput
                                source="companyInfo.city"
                                label="resources.website.fields.companyInfo.city"
                            />}
                        />

                        {/*@ts-ignore*/}
                        <FormInput
                            input={<TextInput
                                source="companyInfo.phone"
                                label="resources.website.fields.companyInfo.phone"

                            />}
                        />
                        {/*@ts-ignore*/}
                        <FormInput
                            input={<TextInput
                                source="companyInfo.email"
                                label="resources.website.fields.companyInfo.email"
                                validate={required()}
                            />}
                        />
                        {/*@ts-ignore*/}
                        <FormInput
                            input={<TextInput
                                source="companyInfo.vat"
                                label="resources.website.fields.companyInfo.vat"

                            />}
                        />
                    </Card.Body>
                </Card>
            </div>

            <div className={"col-12 col-lg-6"}>
                <Card className="mb-5">
                    <Card.Header>
                        <i
                            className="fas fa-question-circle mr-2"
                            aria-hidden="true"
                        />
                        {translate("resources.website.fields.companyInfo.aboutText")}
                    </Card.Header>
                    <Card.Body>
                        {/*@ts-ignore*/}
                        <FormInput
                            input={<RichTextInput
                                source="companyInfo.aboutText"
                                label={false}
                                multiline
                                minLines={10}
                                options={{
                                    modules: {
                                        toolbar: [
                                            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                            [{'list': 'ordered'}, {'list': 'bullet'}],
                                        ]
                                    }
                                }}
                            />}
                        />
                    </Card.Body>
                </Card>
                <Card className="mb-5">
                    <Card.Header>
                        <i
                            className="fas fa-question-circle mr-2"
                            aria-hidden="true"
                        />
                        {translate("general.socialMedia")}
                    </Card.Header>
                    <Card.Body>
                        {/*@ts-ignore*/}
                        <FormInput
                            input={<TextInput
                                source="companyInfo.socials.facebook"
                                label="Facebook"

                            />}
                        />
                        {/*@ts-ignore*/}
                        <FormInput
                            input={<TextInput
                                source="companyInfo.socials.instagram"
                                label="Instagram"

                            />}
                        />
                        {/*@ts-ignore*/}
                        <FormInput
                            input={<TextInput
                                source="companyInfo.socials.linkedin"
                                label="Linkedin"

                            />}
                        />

                        {/*@ts-ignore*/}
                        <FormInput
                            input={<TextInput
                                source="companyInfo.socials.youtube"
                                label="Youtube"
                            />}
                        />
                    </Card.Body>
                </Card>
            </div>
        </div>

    )
}