import { useEffect, useState } from "react";
import { useGetIdentity } from "react-admin";
import { apiUrl } from "../../App";
import {setWhere} from "@misc/data-provider";

const fetchTotal = async (resource, filter) => {
  const url = new URL(<string>apiUrl);

  const path = url.pathname.split("/");

  path.push(resource, "count");

  if (filter && Object.keys(filter).length > 0)
    setWhere(url, filter, "where", "_id", "id");

  url.pathname = path.filter(Boolean).join("/");

  const token = localStorage.getItem("storevan_dealer_token");

  url.pathname = path.filter(Boolean).join("/");

  return (
    await (
      await fetch(url.toString(), {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
    ).json()
  ).count;
};

export default function useResourceCount(resource: string, filter?: any) {
  const [total, setTotal] = useState(0);

  const { loaded, loading, identity } = useGetIdentity();

  useEffect(() => {
    if (loaded && identity) {
      fetchTotal(resource, filter).then((val) => {
        setTotal(val);
      });
    }
  }, [resource, loaded, identity, filter]);

  return {
    count: total,
  };
}
