import {useMediaQuery} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import DefaultIcon from "@material-ui/icons/ViewList";
import {find} from "lodash";
import * as React from "react";
import {getResources, setSidebarVisibility, usePermissions, useTranslate,} from "react-admin";
import {useDispatch, useSelector} from "react-redux";
import DashboardMenuItem from "./components/dashboard-menu-item";
import MenuItemLink from "./components/menu-item-link";

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const useStyles = makeStyles((theme) => ({
    wrapper: {paddingTop: theme.spacing(4)},
}));

const SideMenu: React.FC<Props> = ({onMenuClick, dense, logout}) => {
    const [state, setState] = React.useState({
        users: false,
    });
    const dispatch = useDispatch();

    const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
    const open = useSelector((state: any) => state.admin.ui.sidebarOpen);

    const resources = useSelector(getResources);
    const classes = useStyles();
    const {permissions} = usePermissions();

    const hideSidebar = () => dispatch(setSidebarVisibility(false));

    const MenuTree = [
        {
            label: "Orders",
            resource: "orders",
        },
        {
            label: "Products",
            resource: "products",
        },
        {
            label: "downloads",
            resource: "downloads",
        },
        {
            label: "News",
            resource: "news",
        },
        {
            label: "Realizations",
            resource: "realizations",
        },

    ];

    if (resources.length === 0) {
        return null;
    }

    const handleClick = () => {
        if (isXSmall) {
            hideSidebar();
        }
        onMenuClick && onMenuClick();
    };

    return (
        <nav
            id="sidebarMenu"
            className={`col-md-3 col-lg-2 d-md-block sidebar 
         ${open ? "" : "hide"}`}
        >
            <div className="position-sticky">
                <ul className="nav flex-column primary">
                    <DashboardMenuItem
                        onClick={handleClick}
                        sidebarIsOpen={open}
                    />
                </ul>
                <ul className="nav flex-column">
                    {MenuTree.map((item) => {
                        const resource = find(resources, {
                            name:
                                item.resource === "downloadTypes"
                                    ? "downloadTypes"
                                    : item.resource,
                        });

                        return (
                            <MenuItemLink
                                key={resource.name}
                                to={`/${resource.name}`}
                                primaryText={translate(`resources.${resource.name}.name`, {
                                    smart_count: 2,
                                })}
                                leftIcon={resource.icon ? <resource.icon/> : <DefaultIcon/>}
                                onClick={handleClick}
                                sidebarIsOpen={open}
                            />
                        );
                    })}
                    <MenuItemLink
                        key="website"
                        to="/website"
                        primaryText={translate("general.website")}
                        leftIcon={<i className="far fa-desktop fa-fw"></i>}
                        sidebarIsOpen={open}
                        onClick={handleClick}
                    />
                </ul>

                <ul className="nav flex-column">
                    <MenuItemLink
                        key="profile"
                        to="/profile"
                        primaryText={translate("general.profile")}
                        leftIcon={<i className="far fa-user fa-fw"></i>}
                        sidebarIsOpen={open}
                        onClick={handleClick}
                    />

                    <MenuItemLink
                        key="settings"
                        to="/settings"
                        primaryText={translate("general.configuration")}
                        leftIcon={<i className="far fa-cog fa-fw"></i>}
                        sidebarIsOpen={open}
                        onClick={handleClick}
                    />
                </ul>
            </div>
            {/* {isXSmall && logout} */}
        </nav>
    );
};

export default SideMenu;
