import DeleteButton from "@main-components/buttons/delete-button";
import SaveButton from "@main-components/buttons/save-button";
import { makeStyles } from "@material-ui/core/styles";
import MuiToolbar from "@material-ui/core/Toolbar";
import withWidth from "@material-ui/core/withWidth";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Record, RedirectionSideEffect } from "ra-core";
import * as React from "react";
import {
  Children,
  FC,
  Fragment,
  isValidElement,
  ReactElement,
  ReactNode,
} from "react";
import { ClassesOverride } from "react-admin";
import { FormRenderProps } from "react-final-form";

const useStyles = makeStyles(
  (theme) => ({
    toolbar: {
      backgroundColor: "none",
    },
    desktopToolbar: {
      marginTop: theme.spacing(2),
    },
    mobileToolbar: {
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      padding: "16px",
      width: "100%",
      boxSizing: "border-box",
      flexShrink: 0,
      zIndex: 2,
    },
    defaultToolbar: {
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
    },
    spacer: {
      [theme.breakpoints.down("xs")]: {
        height: "5em",
      },
    },
  }),
  { name: "RaToolbar" }
);

const valueOrDefault = (value, defaultValue) =>
  typeof value === "undefined" ? defaultValue : value;

const Toolbar: FC<ToolbarProps> = (props) => {
  const {
    alwaysEnableSaveButton,
    basePath,
    children,
    className,
    classes: classesOverride,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    pristine,
    record,
    redirect,
    resource,
    saving,
    submitOnEnter,
    undoable,
    width,
    hideDeleteButton,
    ...rest
  } = props;
  const classes = useStyles(props);

  // Use form pristine to enable or disable the save button
  // if alwaysEnableSaveButton is undefined
  const disabled = !valueOrDefault(alwaysEnableSaveButton, !pristine);

  return (
    <Fragment>
      <MuiToolbar
        className={classnames(
          classes.toolbar,
          {
            [classes.mobileToolbar]: width === "xs",
            [classes.desktopToolbar]: width !== "xs",
          },
          className
        )}
        role="toolbar"
        {...rest}
      >
        {Children.count(children) === 0 ? (
          <div className={classes.defaultToolbar}>
            <SaveButton
              handleSubmitWithRedirect={
                handleSubmitWithRedirect || handleSubmit
              }
              disabled={disabled}
              invalid={invalid}
              redirect={redirect}
              saving={saving}
              submitOnEnter={submitOnEnter}
            />
            {record &&
              typeof record.id !== "undefined" &&
              !hideDeleteButton && (
                <DeleteButton
                  basePath={basePath}
                  record={record}
                  resource={resource}
                  undoable={undoable}
                />
              )}
          </div>
        ) : (
          /* @ts-ignore */
          Children.map(children, (button: ReactElement) =>
            button && isValidElement<any>(button)
              ? React.cloneElement(button, {
                  basePath,
                  handleSubmit: valueOrDefault(
                    button.props.handleSubmit,
                    handleSubmit
                  ),
                  handleSubmitWithRedirect: valueOrDefault(
                    button.props.handleSubmitWithRedirect,
                    handleSubmitWithRedirect
                  ),
                  onSave: button.props.onSave,
                  invalid,
                  pristine,
                  record,
                  resource,
                  saving,
                  submitOnEnter: valueOrDefault(
                    button.props.submitOnEnter,
                    submitOnEnter
                  ),
                  undoable: valueOrDefault(button.props.undoable, undoable),
                })
              : null
          )
        )}
      </MuiToolbar>
      <div className={classes.spacer} />
    </Fragment>
  );
};

export interface ToolbarProps<RecordType extends Record = Record> {
  children?: ReactNode;
  alwaysEnableSaveButton?: boolean;
  /* @ts-ignore */
  className?: string;
  classes?: ClassesOverride<typeof useStyles>;
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
  handleSubmit?: FormRenderProps["handleSubmit"];
  invalid?: boolean;
  pristine?: boolean;
  saving?: boolean;
  submitOnEnter?: boolean;
  redirect?: RedirectionSideEffect;
  basePath?: string;
  record?: RecordType;
  resource?: string;
  undoable?: boolean;
  width?: string;
  hideDeleteButton?: boolean;
}

Toolbar.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleSubmitWithRedirect: PropTypes.func,
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  record: PropTypes.any,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  saving: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  undoable: PropTypes.bool,
  width: PropTypes.string,
};

Toolbar.defaultProps = {
  submitOnEnter: true,
  hideDeleteButton: true,
};

export default withWidth({ initialWidth: "xs" })(Toolbar);
