import Card from "@main-components/card";
import FormInput from "@main-components/forms/form-input";
import BooleanInput from "@main-components/inputs/boolean-input";
import TextInput from "@main-components/inputs/text-input";
import ImageInput from "@main-components/inputs/image-input";
import React from "react";
import ImageField from "@main-components/fields/image-field";
import {useTranslate} from "react-admin";

export function ContactPersonSection() {
    const translate = useTranslate()

    return (
        <Card className="mb-5">
            {/* <Card.Header>
                <i
                    className="fas fa-question-circle mr-2"
                    aria-hidden="true"
                />
                {translate("resources.website.sections.contact_person")}
            </Card.Header>*/}
            <Card.Body>
                <div className={"row"}>
                    <div className={"col-12 col-lg-6"}>
                        {/*@ts-ignore*/}
                        <FormInput
                            input={<BooleanInput
                                source="contactPerson.show"
                                label="resources.website.fields.contactPerson.show"
                            />}
                        />

                        {/*@ts-ignore*/}
                        <FormInput
                            input={<TextInput
                                source="contactPerson.info.name"
                                label="resources.website.fields.contactPerson.name"
                            />}
                        />

                        {/*@ts-ignore*/}
                        <FormInput
                            input={<TextInput
                                source="contactPerson.info.role"
                                label="resources.website.fields.contactPerson.role"
                            />}
                        />

                        {/*@ts-ignore*/}
                        <FormInput
                            input={<TextInput
                                source="contactPerson.info.email"
                                label="resources.website.fields.contactPerson.email"
                            />}
                        />

                        {/*@ts-ignore*/}
                        <FormInput
                            input={<TextInput
                                source="contactPerson.info.phone"
                                label="resources.website.fields.contactPerson.phone"
                            />}
                        />

                        <ImageInput
                            source="contactPerson.info.profilePicture"
                            label="general.profile_picture"
                            accept="image/jpeg,image/png"
                        >
                            <ImageField source="src"/>
                        </ImageInput>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}