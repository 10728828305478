// in src/Dashboard.js
import Card from "@main-components/card";
import * as React from "react";
import { LinearProgress, useGetOne, useTranslate } from "react-admin";

export default function CompanyWidget() {
  const companyRes = useGetOne("company-info", 1);
  const translate = useTranslate();

  const { loading, data } = companyRes;
  return (
    <Card className="mt-5 bg-primary">
      <Card.Header>
        <i className="fas fa-question-circle mr-2" aria-hidden="true"></i>

        {translate("general.help_and_support").toUpperCase()}
      </Card.Header>
      <Card.Body>
        <div className="row">
          <div className="col-xs-12 col-lg-6">
            <address>
              <strong>StoreVan Vlaanderen</strong>
              <br></br>
              {loading || !data ? <LinearProgress /> : data.name}
              <br></br>
              {loading || !data ? (
                <LinearProgress />
              ) : (
                data.streetAndNumber
              )}{" "}
              <br></br>
              {loading || !data ? (
                <LinearProgress />
              ) : (
                data.postCodeAndCity
              )}{" "}
              <br></br>
              <a href={`${data ? data.website : ""}`} target="_blank">
                {loading || !data ? <LinearProgress /> : data.website}
              </a>
              <br></br>
            </address>
          </div>
          <div className="col-xs-12 col-lg-6">
            <address>
              <strong>
                {loading || !data ? (
                  <LinearProgress />
                ) : (
                  data.representative.fullName
                )}
              </strong>
              <br></br>
              {loading || !data ? (
                <LinearProgress />
              ) : (
                data.representative.position
              )}
              <br></br>
              <abbr title="Telefoonnummer">Tel:</abbr>{" "}
              {loading || !data ? (
                <LinearProgress />
              ) : (
                data.representative.phone
              )}
              <br></br>
              <abbr title="E-mailadres">E-mail:</abbr>{" "}
              <a href={`mailto:${data ? data.representative.email : ""}`}>
                {loading || !data ? (
                  <LinearProgress />
                ) : (
                  data.representative.email
                )}
              </a>
            </address>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
