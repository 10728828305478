import Card from "@main-components/card";
import FormInput from "@main-components/forms/form-input";
import BooleanInput from "@main-components/inputs/boolean-input";
import {FormDataConsumer, required, useTranslate} from "react-admin";
import TextInput from "@main-components/inputs/text-input";
import React from "react";

export function NotificationSection() {
    const translate = useTranslate()

    return (
        <Card className="mb-5">
            {/* <Card.Header>
                <i
                    className="fas fa-question-circle mr-2"
                    aria-hidden="true"
                />
                {translate("resources.website.sections.notification")}
            </Card.Header>*/}
            <Card.Body>
                <div className={"row"}>
                    <div className={"col-12 col-lg-6"}>
                        {/*@ts-ignore*/}
                        <FormInput
                            input={<BooleanInput
                                source="notification.show"
                                label="resources.website.fields.notifications.show"
                            />}
                        />
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                if (!formData.notification.show) return null
                                return (
                                    /*@ts-ignore**/
                                    <FormInput
                                        input={<TextInput
                                            source="notification.notificationMessage"
                                            label="resources.website.fields.notifications.message"
                                            validate={formData.notification.show ? required() : undefined}
                                        />}
                                    />
                                )

                            }}
                        </FormDataConsumer>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}