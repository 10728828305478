import Card from "@main-components/card";
import React from "react";
import BooleanInput from "@main-components/inputs/boolean-input";
import FormInput from "@main-components/forms/form-input";
import {FormDataConsumer, useTranslate} from "react-admin";
import {Table, TableBody, TableCell, TableContainer, TableRow} from "@material-ui/core";
import TimeInput from "@main-components/inputs/time-input";

export function OpeningHoursSection() {
    const daysOfWeek = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

    const translate = useTranslate()
    return (
        <Card className="mb-5">
            {/* <Card.Header>
                <i
                    className="fas fa-question-circle mr-2"
                    aria-hidden="true"
                />
                {translate("resources.website.sections.opening_hours")}
            </Card.Header>*/}
            <Card.Body>
                {/*@ts-ignore*/}
                <FormInput
                    input={<BooleanInput
                        source="openingHours.show"
                        label="resources.website.fields.openingHours.show"
                    />}
                />
                <TableContainer>
                    <Table>
                        <TableBody>
                            {daysOfWeek.map((day) => (
                                <TableRow key={day}>
                                    <TableCell>
                                        <h5>{translate(`general.weekDays.${day}`)}</h5>
                                    </TableCell>

                                    {/* Open */}
                                    <TableCell>
                                        {/*@ts-ignore*/}
                                        <FormInput
                                            input={<BooleanInput
                                                source={`openingHours.config.${day}.isOpen`}
                                                label={`resources.website.fields.openingHours.isOpen`}
                                            />}
                                        />

                                        <FormDataConsumer>
                                            {({formData, ...rest}) => {
                                                const enabled = !!formData.openingHours.config[day].isOpen
                                                return (
                                                    <>
                                                        {/*@ts-ignore*/}
                                                        <FormInput
                                                            input={<BooleanInput
                                                                source={`openingHours.config.${day}.hasBreak`}
                                                                label={`resources.website.fields.openingHours.hasBreak`}
                                                                disabled={!enabled}
                                                            />}
                                                        />
                                                    </>
                                                )
                                            }}
                                        </FormDataConsumer>

                                    </TableCell>

                                    <FormDataConsumer>
                                        {({formData, ...rest}) => {
                                            const needsOpeningInputs = !!formData.openingHours.config[day].isOpen
                                            const needsBreakInput = !!formData.openingHours.config[day].hasBreak


                                            return (
                                                <>
                                                    {/* Opening Hour */}
                                                    <TableCell>
                                                        {/*@ts-ignore*/}
                                                        <FormInput
                                                            input={
                                                                <TimeInput
                                                                    source={`openingHours.config.${day}.openingHour`}
                                                                    label={`resources.website.fields.openingHours.openingHour`}
                                                                    variant="outlined"
                                                                    /*  validate={needsOpeningInputs ? required() : undefined}*/
                                                                    disabled={!needsOpeningInputs}
                                                                />
                                                            }
                                                        />
                                                    </TableCell>

                                                    {/* Closing Hour */}
                                                    <TableCell>
                                                        {/*@ts-ignore*/}
                                                        <FormInput
                                                            input={
                                                                <TimeInput
                                                                    source={`openingHours.config.${day}.closingHour`}
                                                                    label={`resources.website.fields.openingHours.closingHour`}
                                                                    variant="outlined"
                                                                    /*  validate={needsOpeningInputs ? required() : undefined}*/
                                                                    disabled={!needsOpeningInputs}
                                                                />
                                                            }
                                                        />
                                                    </TableCell>
                                                    {/* Break Start */}
                                                    <TableCell>
                                                        {/*@ts-ignore*/}
                                                        <FormInput
                                                            input={
                                                                <TimeInput
                                                                    source={`openingHours.config.${day}.breakStart`}
                                                                    label={`resources.website.fields.openingHours.breakStart`}
                                                                    variant="outlined"
                                                                    /* validate={needsBreakInput ? required() : undefined}*/
                                                                    disabled={!needsBreakInput}
                                                                />
                                                            }
                                                        />
                                                    </TableCell>

                                                    {/* Break End */}
                                                    <TableCell>
                                                        {/*@ts-ignore*/}
                                                        <FormInput
                                                            input={
                                                                <TimeInput
                                                                    source={`openingHours.config.${day}.breakEnd`}
                                                                    label={`resources.website.fields.openingHours.breakEnd`}
                                                                    variant="outlined"
                                                                    /* validate={needsBreakInput ? required() : undefined}*/
                                                                    disabled={!needsBreakInput}
                                                                />
                                                            }
                                                        />
                                                    </TableCell>
                                                </>
                                            )
                                        }
                                        }
                                    </FormDataConsumer>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card.Body>
        </Card>
    );
}