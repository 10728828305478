import decodeJwt from "jwt-decode";
const AuthProvider = (loginUrl) => ({
  login: ({ username, password }) => {
    const options = {
      body: JSON.stringify({ email: username, password }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
    };

    return fetch(new URL(loginUrl).toString(), options)
      .then(async (response) => {
        if (response.status < 200 || response.status >= 300) {
          const r = await response.json();
          throw new Error(
            r && r.error.message
              ? "ra.auth." + r.error.message
              : response.statusText
          );
        }
        return response.json();
      })
      .then(({ token }) => {
        const decodedToken = decodeJwt(token);
        localStorage.setItem("storevan_dealer_token", token);
        localStorage.setItem("dealer_role", decodedToken.role);
        localStorage.setItem(
          "storevan_dealer_profile",
          JSON.stringify(decodedToken)
        );
      });
  },
  logout: () => {
    localStorage.removeItem("storevan_dealer_token");
    localStorage.removeItem("storevan_dealer_profile");
    localStorage.removeItem("dealer_role");
    return Promise.resolve();
  },
  checkError: (error) => {
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem("storevan_dealer_token")
      ? Promise.resolve()
      : Promise.reject();
  },
  getPermissions: () => {
    const role = localStorage.getItem("dealer_role");
    return role ? Promise.resolve(role) : Promise.resolve("guest");
  },
  getIdentity: () => {
    const { id, firstName, lastName, email } = JSON.parse(
      localStorage.getItem("storevan_dealer_profile")
    );

    return Promise.resolve({
      id,
      fullName: firstName + " " + lastName,
      avatar: undefined,
    });
  },
});

export default AuthProvider;
