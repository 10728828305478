import moment from "moment";

const DateTimeUtils = {
  format: (date, formatString) => {
    return moment(date).format(formatString);
  },
  addDays: (a, days) => {
    return moment(a).clone().add(days, 'days').toDate();
  }
};

export default DateTimeUtils;
