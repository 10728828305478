import Button from "@main-components/buttons";
import ImageField from "@main-components/fields/image-field";
import SimpleForm from "@main-components/forms/simple-form";
import ArrayInput from "@main-components/inputs/array-input";
import ImageInput from "@main-components/inputs/image-input";
import TextInput from "@main-components/inputs/text-input";
import ShowContainer from "@main-components/show-container";
import * as React from "react";
import {
  required,
  useAuthenticated,
  useEditController,
  useGetIdentity,
  useNotify,
  useRefresh,
  useTranslate
} from "react-admin";
import { Card } from "react-bootstrap";
import AltContactFormIterator from "./alt-contact-iterator";

export const transformDealerData = (data) => {
  if (data.logoUrl && data.logoUrl.rawFile) {
    data.logoUrl = data.logoUrl.rawFile;
  }

  if (
    data.mainContact.profilePictureUrl &&
    data.mainContact.profilePictureUrl.rawFile
  ) {
    data.mainContact.profilePictureUrl =
      data.mainContact.profilePictureUrl.rawFile;
  }

  data.alternativeContacts = data.alternativeContacts
    ? data.alternativeContacts.map((contact) => {
        if (contact.profilePictureUrl && contact.profilePictureUrl.rawFile) {
          contact.profilePictureUrl = contact.profilePictureUrl.rawFile;
        }
        return contact;
      })
    : [];

  return data;
};

const TradeInfoForm = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const editController = useEditController({
    ...props,
    id: props.record.id,
    undoable: false,
    onSuccess: () => {
      notify("resources.profile.notifications.details_update", "info", {
        smart_count: 1,
      });
      refresh();
    },
    transform: transformDealerData,
  });

  if (!editController.record) return null;

  return (
    <SimpleForm {...editController}>
      <ImageInput source="logoUrl" accept="image/jpeg,image/png">
        <ImageField source="src" />
      </ImageInput>
      <TextInput
        source="brandName"
        validate={[required()]}
        helperText="resources.profile.messages.brand_name"
      />
      <TextInput
        source="businessAddress"
        //validate={[required()]}
        helperText="resources.profile.messages.business_address"
      />
    </SimpleForm>
  );
};

const CompanyInfoForm = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const editController = useEditController({
    ...props,
    id: props.record.id,
    undoable: false,
    onSuccess: () => {
      notify("resources.profile.notifications.details_update", "info", {
        smart_count: 1,
      });

      refresh();
    },
    transform: transformDealerData,
  });

  return (
    <SimpleForm {...editController}>
      <TextInput source="vatNumber" />
      <TextInput
        source="companyName"
        helperText="resources.profile.messages.company_name"
      />
      <TextInput
        source="headOfficeAddress"
        //validate={[required()]}
        helperText="resources.profile.messages.head_office_address"
      />
    </SimpleForm>
  );
};

const ResponsibleInfo = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const editController = useEditController({
    ...props,
    id: props.record.id,
    undoable: false,
    onSuccess: () => {
      notify("resources.profile.notifications.details_update", "info", {
        smart_count: 1,
      });
      refresh();
    },
    transform: transformDealerData,
  });

  if (!editController.record) return null;

  return (
    <SimpleForm {...editController}>
      <ImageInput
        source="mainContact.profilePictureUrl"
        label="general.profile_picture"
        accept="image/jpeg,image/png"
      >
        <ImageField source="src" />
      </ImageInput>

      <TextInput
        source="mainContact.firstName"
        label="general.first_name"
        validate={[required()]}
        helperText="resources.profile.messages.main_contact_first_name"
      />
      <TextInput
        source="mainContact.lastName"
        label="general.last_name"
        validate={[required()]}
        helperText="resources.profile.messages.main_contact_last_name"
      />

      <TextInput
        source="mainContact.email"
        label="general.email"
        validate={[required()]}
        helperText="resources.profile.messages.main_contact_email"
      />

      <TextInput
        source="mainContact.companyRole"
        label="resources.profile.fields.companyRole"
        helperText="resources.profile.messages.main_contact_role"
        //  validate={[required()]}
      />
      <TextInput
        source="mainContact.phoneNumber"
        label="general.phone"
        //  validate={[required()]}
        helperText="resources.profile.messages.main_contact_phone"
      />
    </SimpleForm>
  );
};

const AlternativeContactForm = (props) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();

  const editController = useEditController({
    ...props,
    id: props.record.id,
    undoable: false,
    onSuccess: () => {
      notify("resources.profile.notifications.details_update", "info", {
        smart_count: 1,
      });
      refresh();
    },
    transform: transformDealerData,
  });

  return (
    <SimpleForm
      {...editController}
      /*  save={(values) => {
        console.log(values);
      }} */
    >
      <ArrayInput source="alternativeContacts" label=" ">
        <AltContactFormIterator
          className="row"
          removeButton={
            <Button label="ra.action.remove" variant="outline" color="danger" />
          }
        >
          <ImageInput
            source="profilePictureUrl"
            label="general.profile_picture"
            accept="image/jpeg,image/png"
          >
            <ImageField source="src" />
          </ImageInput>

          <TextInput
            source="email"
            label="general.email"
            validate={[required()]}
          />
          <TextInput
            source="firstName"
            label="general.first_name"
            validate={[required()]}
          />
          <TextInput
            source="lastName"
            label="general.last_name"
            validate={[required()]}
          />
          <TextInput
            source="companyRole"
            label="resources.profile.fields.companyRole"
            //validate={[required()]}
            helperText="resources.profile.messages.alt_contact_role"
          />
          <TextInput
            source="phoneNumber"
            label="general.phone"
            // validate={[required()]}
          />
        </AltContactFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
};

const Profile = (props) => {
  const translate = useTranslate();
  const [activeTab, setActiveTab] = React.useState(0);
  const { record } = props;
  if (!record) return null;

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3">
        <h1>{translate("general.profile", { smart_count: 1 })}</h1>
      </div>
      <div className="row">
        <div className="col-auto mb-4">
          <form>
            <label className="form-label">
              {translate("resources.profile.fields.identificationId")}
            </label>
            <div className="input-group">
              <span className="input-group-text" id="dealerID-addon">
                SVVD-ID
              </span>
              <input
                className="form-control"
                type="text"
                placeholder="0105"
                aria-describedby="dealerIDHelp"
                disabled
                value={record.identificationId}
              />
            </div>
            <div id="dealerIDHelp" className="form-text">
              {translate("resources.profile.messages.dealer_id")}
            </div>
          </form>
        </div>
      </div>

      <nav>
        <div
          className="nav nav-tabs border-bottom-0"
          id="nav-tab"
          role="tablist"
        >
          <a
            className={`nav-link ${activeTab === 0 ? "active" : ""}`}
            id="nav-general-tab"
            data-toggle="tab"
            href="#nav-general"
            role="tab"
            aria-controls="nav-general"
            aria-selected="true"
            onClick={(e) => {
              e.preventDefault();
              setActiveTab(0);
            }}
          >
            {translate("resources.profile.sections.general")}
          </a>
          <a
            className={`nav-link ${activeTab === 1 ? "active" : ""}`}
            id="nav-contacts-tab"
            data-toggle="tab"
            href="#nav-contacts"
            role="tab"
            aria-controls="nav-contacts"
            aria-selected="false"
            onClick={(e) => {
              e.preventDefault();
              setActiveTab(1);
            }}
          >
            {translate("resources.profile.sections.additional_contacts")}
          </a>
        </div>
      </nav>

      <div className="tab-content p-4 border" id="nav-tabContent">
        <div
          className={`tab-pane fade  ${activeTab === 0 ? "show active" : ""}`}
          id="nav-general"
          role="tabpanel"
          aria-labelledby="nav-general-tab"
        >
          <div className="row">
            <div className="col-xs-12 col-md-6 col-xl-4 mb-4">
              <Card>
                <Card.Header>
                  <i
                    className="far fa-warehouse-alt mr-2"
                    aria-hidden="true"
                  ></i>
                  {translate("resources.profile.titles.trade_data")}
                </Card.Header>
                <Card.Body className="d-flex flex-column">
                  <TradeInfoForm {...props} />
                </Card.Body>
              </Card>
            </div>
            <div className="col-xs-12 col-md-6 col-xl-4 mb-4">
              <Card>
                <Card.Header>
                  <i className="far fa-briefcase mr-2" aria-hidden="true"></i>
                  {translate("resources.profile.titles.official_company_info")}
                </Card.Header>
                <Card.Body className="d-flex flex-column">
                  <CompanyInfoForm {...props} />
                </Card.Body>
              </Card>
            </div>
            <div className="col-xs-12 col-md-6 col-xl-4 mb-4">
              <Card>
                <Card.Header>
                  <i className="far fa-user mr-2" aria-hidden="true"></i>
                  {translate(
                    "resources.profile.titles.responsible_contact_details"
                  )}
                </Card.Header>
                <Card.Body className="d-flex flex-column">
                  <ResponsibleInfo {...props} />
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <div
          className={`tab-pane fade  ${activeTab === 1 ? "show active" : ""}`}
          id="nav-contacts"
          role="tabpanel"
          aria-labelledby="nav-contacts-tab"
        >
          <AlternativeContactForm {...props} />
        </div>
      </div>
    </>
  );
};

const ShowProfile = (props) => {
  useAuthenticated();
  const translate = useTranslate();

  const { loaded, loading, identity } = useGetIdentity();
  if (!loaded || !identity) return null;

  return (
    <ShowContainer
      resource="profile"
      basePath="profile"
      id={identity.id.toString()}
      actions={false}
      component={"div"}
    >
      <Profile {...props} resource="profile" basePath="profile" />
    </ShowContainer>
  );
};

export default ShowProfile;
