import * as React from "react";
import RealizationList from "./list";
import RealizationShow from "./show";

const resource = {
  list: RealizationList,
  // create: RealizationCreate,
  // edit: RealizationEdit,
  show: RealizationShow,
  icon: () => <i className="far fa-image fa-fw"></i>,
};

export default resource;
