import CardContentInner from "@main-components/layout/CardContentInner";
import classnames from "classnames";
import PropTypes from "prop-types";
import {FormWithRedirect, FormWithRedirectProps, Record, RedirectionSideEffect,} from "ra-core";
import * as React from "react";
import {Children, FC, HtmlHTMLAttributes, ReactElement, ReactNode} from "react";
import {Form} from "react-bootstrap";
import {FormRenderProps} from "react-final-form";
import FormInput from "../form-input";
import Toolbar from "../toolbar";

const SimpleForm: FC<SimpleFormProps> = (props) => (
    <FormWithRedirect
        {...props}
        /* @ts-ignore */
        render={(formProps) => <SimpleFormView {...formProps} />}
    />
);

SimpleForm.propTypes = {
    children: PropTypes.node,
    initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    // @ts-ignore
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    save: PropTypes.func,
    saving: PropTypes.bool,
    submitOnEnter: PropTypes.bool,
    toolbar: PropTypes.element,
    undoable: PropTypes.bool,
    validate: PropTypes.func,
    version: PropTypes.number,
    sanitizeEmptyValues: PropTypes.bool,
};

export interface SimpleFormProps
    extends Omit<FormWithRedirectProps, "render">,
        Omit<HtmlHTMLAttributes<HTMLFormElement>,
            "defaultValue" | "onSubmit" | "children"> {
    basePath?: string;
    children: ReactNode;
    className?: string;
    initialValues?: any;
    margin?: "none" | "normal" | "dense";
    resource?: string;
    submitOnEnter?: boolean;
    toolbar?: ReactElement;
    undoable?: boolean;
    variant?: "standard" | "outlined" | "filled";
}

const SimpleFormView: FC<SimpleFormViewProps> = ({
    basePath,
    children,
    className,
    handleSubmit,
    handleSubmitWithRedirect,
    invalid,
    margin,
    pristine,
    record,
    redirect,
    resource,
    saving,
    submitOnEnter,
    toolbar,
    undoable,
    variant,
    ...rest
}) => (
    <Form
        className={classnames("simple-form", className)}
        /* @ts-ignore */
        {...sanitizeRestProps(rest)}
    >
        <CardContentInner>
            {Children.map(
                /* @ts-ignore */
                children,
                (input: ReactElement) => {
                    if (!input) return null
                    const chlProps = {
                        basePath: basePath,
                        record: record,
                        resource: resource
                    }

                    if (!!input?.props?.source) {
                        // @ts-ignore
                        return <FormInput
                            {...chlProps}
                            input={input}
                            variant={input.props.variant || variant}
                            margin={input.props.margin || margin}
                        />
                    }
                    return (
                        React.cloneElement(input, {
                            ...chlProps
                        })
                    )
                }
            )}
        </CardContentInner>
        {toolbar &&
            React.cloneElement(toolbar, {
                basePath,
                handleSubmitWithRedirect,
                handleSubmit,
                invalid,
                pristine,
                record,
                redirect,
                resource,
                saving,
                submitOnEnter,
                undoable,
            })}
    </Form>
);

SimpleFormView.propTypes = {
    basePath: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    /* @ts-ignore */
    handleSubmit: PropTypes.func, // passed by react-final-form
    /* @ts-ignore */
    invalid: PropTypes.bool,
    /* @ts-ignore */
    pristine: PropTypes.bool,
    // @ts-ignore
    record: PropTypes.object,
    resource: PropTypes.string,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
    saving: PropTypes.bool,
    submitOnEnter: PropTypes.bool,
    toolbar: PropTypes.element,
    undoable: PropTypes.bool,
    validate: PropTypes.func,
};

export interface SimpleFormViewProps extends FormRenderProps {
    basePath?: string;
    className?: string;
    margin?: "none" | "normal" | "dense";
    handleSubmitWithRedirect?: (redirectTo: RedirectionSideEffect) => void;
    record?: Record;
    redirect?: RedirectionSideEffect;
    resource?: string;
    save?: () => void;
    saving?: boolean;
    toolbar?: ReactElement;
    undoable?: boolean;
    variant?: "standard" | "outlined" | "filled";
    submitOnEnter?: boolean;
    __versions?: any; // react-final-form internal prop, missing in their type
}

SimpleFormView.defaultProps = {
    submitOnEnter: true,
    toolbar: <Toolbar/>,
};

const sanitizeRestProps = ({
    active,
    dirty,
    dirtyFields,
    dirtyFieldsSinceLastSubmit,
    dirtySinceLastSubmit,
    error,
    errors,
    form,
    hasSubmitErrors,
    hasValidationErrors,
    initialValues,
    modified = null,
    modifiedSinceLastSubmit,
    save = null,
    submitError,
    submitErrors,
    submitFailed,
    submitSucceeded,
    submitting,
    touched = null,
    valid,
    validating,
    values,
    visited = null,
    __versions = null,
    ...props
}) => props;

export default SimpleForm;
