import Confirm from "@main-components/layout/Confirm";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import ActionDelete from "@material-ui/icons/Delete";
import classnames from "classnames";
import inflection from "inflection";
import PropTypes from "prop-types";
import {
  OnFailure,
  OnSuccess,
  Record,
  RedirectionSideEffect,
  useDeleteWithConfirmController,
  useTranslate,
} from "ra-core";
import React, {
  FC,
  Fragment,
  ReactElement,
  ReactEventHandler,
  SyntheticEvent,
} from "react";
import Button, { ButtonProps } from "..";

const DeleteWithConfirmButton: FC<DeleteWithConfirmButtonProps> = (props) => {
  const {
    basePath,
    classes: classesOverride,
    className,
    confirmTitle = "ra.message.delete_title",
    confirmContent = "ra.message.delete_content",
    icon = defaultIcon,
    label = "ra.action.delete",
    onClick,
    record,
    resource,
    redirect = "list",
    onSuccess,
    onFailure,
    ...rest
  } = props;
  const translate = useTranslate();
  const classes = useStyles(props);
  const {
    open,
    loading,
    handleDialogOpen,
    handleDialogClose,
    handleDelete,
  } = useDeleteWithConfirmController({
    /* @ts-ignore */
    resource,
    record,
    redirect,
    basePath,
    onClick,
    onSuccess,
    onFailure,
  });

  return (
    <Fragment>
      <Button
        onClick={handleDialogOpen}
        label={label}
        className={classnames(
          "ra-delete-button",
          classes.deleteButton,
          className
        )}
        key="button"
        {...rest}
      >
        {icon}
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title={confirmTitle}
        content={confirmContent}
        translateOptions={{
          name: translate(`resources.${resource}.forcedCaseName`, {
            smart_count: 1,
            _: inflection.humanize(
              translate(`resources.${resource}.name`, {
                smart_count: 1,
                _: inflection.singularize(resource),
              }),
              true
            ),
          }),
          /* @ts-ignore */
          id: record.id,
        }}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

const defaultIcon = <ActionDelete />;

const useStyles = makeStyles(
  (theme) => ({
    deleteButton: {
      color: theme.palette.error.main,
      "&:hover": {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
  }),
  { name: "RaDeleteWithConfirmButton" }
);

interface Props {
  basePath?: string;
  classes?: object;
  className?: string;
  confirmTitle?: string;
  confirmContent?: string;
  icon?: ReactElement;
  label?: string;
  onClick?: ReactEventHandler<any>;
  record?: Record;
  redirect?: RedirectionSideEffect;
  resource?: string;
  // May be injected by Toolbar - sanitized in Button
  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>;
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
  invalid?: boolean;
  pristine?: boolean;
  saving?: boolean;
  submitOnEnter?: boolean;
  undoable?: boolean;
  onSuccess?: OnSuccess;
  onFailure?: OnFailure;
}

export type DeleteWithConfirmButtonProps = Props & ButtonProps;

DeleteWithConfirmButton.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  confirmTitle: PropTypes.string,
  confirmContent: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.any,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  icon: PropTypes.element,
};

export default DeleteWithConfirmButton;
