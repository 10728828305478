import Button from "@main-components/buttons";
import Card from "@main-components/card";
import Datagrid from "@main-components/data-grid";
import DateField from "@main-components/fields/date-field";
import TextField from "@main-components/fields/text-field";
import List from "@main-components/list";
import Empty from "@main-components/list/components/empty";
import OrderStatusField from "@main/orders/shared/status-field";
import { useMediaQuery } from "@material-ui/core";
import useResourceCount from "@misc/utils/useResourceCount";
import * as React from "react";
import { useState } from "react";
import { useDataProvider, useTranslate } from "react-admin";
import { useSelector } from "react-redux";

const EmptyIcon = (props) => (
  <i
    className={`fal fa-shopping-cart mr-2`}
    style={{ fontSize: "7.5em" }}
    aria-hidden="true"
  ></i>
);

export default function OrdersWidget() {
  const [state, setState] = useState<any>({});
  const dataProvider = useDataProvider();
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const translate = useTranslate();
  const { count } = useResourceCount("orders");

  const dashboardTotal = useSelector((state: any) => {
    return state.admin.resources["dashboard-orders"]?.list?.total;
  });
  return (
    <Card>
      <Card.Header>
        <i className="fal fa-shopping-cart mr-2" aria-hidden="true"></i>
        {translate("resources.orders.titles.open_orders")}
      </Card.Header>
      <Card.Body>
        <List
          resource="dashboard-orders"
          basePath="/orders"
          hasCreate={true}
          hasEdit={false}
          hasList={true}
          hasShow={true}
          title=" "
          sort={{ field: "date", order: "desc" }}
          bulkActionButtons={false}
          exporter={false}
          component={"div"}
          actions={false}
          mainActions={false}
          pagination={false}
          filter={{
            status: {
              neq: "SENT",
            },
          }}
          perPage={5}
          empty={
            <Empty
              hideActions
              resourceOverride="orders"
              basePath="/orders"
              subtitle=" "
              icon={
                <div>
                  <img src="/img/illustrations/orders.svg" className="w-100" />
                </div>
              }
            />
          }
        >
          <Datagrid rowClick="show" resource="orders">
            <TextField source="orderId" sortable={false} />
            <DateField source="date" showTime sortable={false} />
            <TextField source="reference" />

            <OrderStatusField />
          </Datagrid>
        </List>
        <div
          className={`mt-4 mb-4 ${
            dashboardTotal === 0
              ? "d-flex align-items-center justify-content-center"
              : ""
          }`}
        >
          <Button
            className="mr-1"
            label="resources.orders.actions.place_order"
            block={false}
            link
            to="/orders?newOrder=true"
          >
            <span>
              <i className="fas fa-plus-circle mr-1" aria-hidden="true" />
            </span>
          </Button>
          {dashboardTotal > 0 && (
            <Button
              label={`resources.orders.actions.see_all_orders`}
              labelParams={{ total: count }}
              block={false}
              link
              to="/orders"
              variant="outline"
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
}
