import { CheckboxProps } from "@material-ui/core/Checkbox";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import { get } from "lodash";
import PropTypes from "prop-types";
import { ChoicesInputProps, useInput, useTranslate, warning } from "ra-core";
import * as React from "react";
import { FunctionComponent, useCallback } from "react";
import InputHelperText from "../InputHelperText";
import defaultSanitizeRestProps from "../sanitizeRestProps";
import CheckboxGroupInputItem from "./item";

const sanitizeRestProps = ({
  setFilter,
  setPagination,
  setSort,
  loaded,
  ...rest
}: any) => defaultSanitizeRestProps(rest);

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    label: {
      transform: "translate(0, 8px) scale(0.75)",
      transformOrigin: `top ${theme.direction === "ltr" ? "left" : "right"}`,
    },
  }),
  { name: "RaCheckboxGroupInput" }
);

const CheckboxGroupInput: FunctionComponent<
  ChoicesInputProps<CheckboxProps> & FormControlProps
> = (props) => {
  const {
    choices = [],
    className,
    classes: classesOverride,
    format,
    helperText,
    label,
    margin = "dense",
    onBlur,
    onChange,
    onFocus,
    optionText,
    optionValue,
    options,
    parse,
    resource,
    row,
    source,
    translate,
    translateChoice,
    validate,
    ...rest
  } = props;
  const classes = useStyles(props);

  warning(
    source === undefined,
    `If you're not wrapping the CheckboxGroupInput inside a ReferenceArrayInput, you must provide the source prop`
  );

  warning(
    choices === undefined,
    `If you're not wrapping the CheckboxGroupInput inside a ReferenceArrayInput, you must provide the choices prop`
  );

  const {
    id,
    input: { onChange: finalFormOnChange, onBlur: finalFormOnBlur, value },
    isRequired,
    meta: { error, touched },
  } = useInput({
    format,
    onBlur,
    onChange,
    onFocus,
    parse,
    resource,
    /* @ts-ignore */
    source,
    validate,
    ...rest,
  });

  const handleCheck = useCallback(
    (event) => {
      let newValue;
      try {
        // try to convert string value to number, e.g. '123'
        newValue = JSON.parse(event.target.value);
      } catch (e) {
        // impossible to convert value, e.g. 'abc'
        newValue = event.target.value;
      }

      const isChecked = event.currentTarget.checked;

      if (isChecked) {
        finalFormOnChange([...(value || []), ...[newValue]]);
      } else {
        finalFormOnChange(value.filter((v) => v != newValue)); // eslint-disable-line eqeqeq
      }
      finalFormOnBlur(); // HACK: See https://github.com/final-form/react-final-form/issues/365#issuecomment-515045503
    },
    [finalFormOnChange, finalFormOnBlur, value]
  );

  const translator = useTranslate();

  return (
    <FormControl
      component="fieldset"
      margin={"none"}
      error={touched && !!error}
      className={classnames(classes.root, className)}
      {...sanitizeRestProps(rest)}
    >
      {/*  <FormLabel component="legend" className={classes.label}>
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      </FormLabel> */}
      <FormGroup row={row} className="mb-0">
        {choices.map((choice) => (
          <CheckboxGroupInputItem
            key={get(choice, optionValue)}
            choice={choice}
            id={id}
            onChange={handleCheck}
            options={options}
            optionText={optionText}
            optionValue={optionValue}
            translateChoice={translateChoice}
            value={value}
            singleChoice={choices.length === 1}
          />
        ))}
      </FormGroup>
      {touched && error && (
        <FormHelperText>
          <InputHelperText
            /* @ts-ignore */
            touched={touched}
            error={error}
            helperText={helperText}
          />
        </FormHelperText>
      )}
    </FormControl>
  );
};

CheckboxGroupInput.propTypes = {
  /* @ts-ignore */
  choices: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  label: PropTypes.string,
  source: PropTypes.string,
  options: PropTypes.object,
  optionText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element,
  ]),
  optionValue: PropTypes.string,
  row: PropTypes.bool,
  resource: PropTypes.string,
  translateChoice: PropTypes.bool,
};

CheckboxGroupInput.defaultProps = {
  options: {},
  optionText: "name",
  optionValue: "id",
  translateChoice: true,
  fullWidth: true,
  row: false,
};

export default CheckboxGroupInput;
