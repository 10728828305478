import Card from "@main-components/card";
import BooleanInput from "@main-components/inputs/boolean-input";
import React from "react";
import {required, useTranslate} from "react-admin";
import {useArrayInputStyles} from "@main/orders/create";
import FormInput from "@main-components/forms/form-input";
import ArrayInput from "@main-components/inputs/array-input";
import Button from "@main-components/buttons";
import TextInput from "@main-components/inputs/text-input";
import SimpleFormIterator from "@main-components/forms/simple-form/iterator";
import ImageInput from "@main-components/inputs/image-input";
import ImageField from "@main-components/fields/image-field";

export function RealizationsSection() {
    const translate = useTranslate()


    return (
        <Card className="mb-5">
            {/*   <Card.Header>
                <i
                    className="fas fa-question-circle mr-2"
                    aria-hidden="true"
                />
                {translate("resources.website.sections.realizations")}
            </Card.Header>*/}
            <Card.Body>
                <div className={"row"}>
                    <div className={"col-12 col-lg-6"}>
                        {/* @ts-ignore*/}
                        <FormInput
                            input={
                                <BooleanInput
                                    source="realisations.show"
                                    label="resources.website.fields.realizations.show"
                                />
                            }
                        />

                        {/* <FormDataConsumer>
                    {({formData, ...rest}) => {
                        if (!formData.realisations.show) return null

                        return (
                            <GalleryInput/>
                        )
                    }}
                </FormDataConsumer>
*/}
                        <GalleryInput/>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}

function GalleryInput() {
    const arrayInputClasses = useArrayInputStyles();

    return (
        <ArrayInput
            label={"resources.website.fields.realizations.gallery"}
            source="realisations.gallery"
            /*   defaultValue={[{url: null, description: null, isCoverImage: true}]}*/
            /*  validate={[required()]}*/
        >
            <SimpleFormIterator
                addButton={
                    <Button
                        label="resources.realizations.actions.add_photo"
                        variant="outline"
                    />
                }
                removeButton={
                    <Button
                        label="ra.action.delete"
                        variant="link"
                        color="danger"
                        style={{color: "red"}}
                    />
                }
                /* @ts-ignore */
                classes={{
                    line: arrayInputClasses.line,
                }}
            >

                <ImageInput
                    label="general.picture"
                    source="url"
                    accept="image/jpeg,image/png"
                    InputProps={{
                        placeholder: "general.select_file",
                    }}
                    validate={[required()]}
                >
                    <ImageField source="src"/>
                </ImageInput>
                <TextInput
                    source="description"
                    label="general.description"
                    /* validate={[required()]}*/
                    multiline
                    rows={3}
                    as="textarea"
                />

                {/*    <RealizationCoverImageInput/>*/}
            </SimpleFormIterator>
        </ArrayInput>
    )
}