import Button from "@main-components/buttons";
import Card from "@main-components/card";
import RichTextField from "@main-components/fields/rich-text-field";
import DateTimeUtils from "@misc/utils/date-time-utils";
import TextUtils from "@misc/utils/text";
import useResourceCount from "@misc/utils/useResourceCount";
import * as React from "react";
import { useTranslate } from "react-admin";
import { useSelector } from "react-redux";

export default function LastNewsWidget() {
  const translate = useTranslate();
  const { count } = useResourceCount("news");
  const latestNewsState = useSelector((state: any) => {
    return state.admin.resources["dashboard-news"];
  });

  if (
    !latestNewsState ||
    !latestNewsState.list.total ||
    !latestNewsState.data
  ) {
    return null;
  }

  const news = latestNewsState.data[latestNewsState.list.ids[0]];

  return (
    <Card className="mt-5">
      <div
        className="card-img-top"
        style={{
          background: `url(${
            news.featuredImage ? news.featuredImage : "img/example.jpg"
          })`,
          backgroundSize: "cover",
          height: "291px",
          backgroundRepeat: "no-repeat",
          width: "100%",
          backgroundPosition: "center",
        }}
      >
        {/*  {news.featuredImage ? (
          <img src={news.featuredImage} alt="" />
        ) : (
          <img src="img/example.jpg" alt="" />
        )} */}
      </div>
      <div className="card-img-overlay text-right">
        <span className="badge bg-dark mt-3 mr-3">
          <i className="fal fa-clock"></i>{" "}
          {DateTimeUtils.format(news.date, "DD/MM/YYYY ")} om {""}
          {DateTimeUtils.format(news.date, "HHumm")}
        </span>
      </div>
      <Card.Body>
        <h5 className="card-title">{news.title}</h5>
        <div className="mb-3">
          <RichTextField
            record={{
              ...news,
              content: TextUtils.htmlSubstring(news.content, 300),
            }}
            source="content"
          />
        </div>

        <div className={`mt-4  mb-4 `}>
          <Button
            className="mr-1"
            color="primary"
            label={`resources.news.actions.see_news`}
            block={false}
            link
            to={`/news/${news.id}/show`}
            alignIcon="right"
          >
            <span>
              <i className="fas fa-eye mr-1" aria-hidden="true" />
            </span>
          </Button>

          <Button
            label="resources.news.actions.see_overview"
            link
            to="/news"
            variant="outline"
            block={false}
          />
        </div>
      </Card.Body>
    </Card>
  );
}
