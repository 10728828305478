import { apiUrl } from "@app";
import LoadingButton from "@main-components/buttons/loading-button";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useState } from "react";
import {
  fetchEnd,
  fetchStart,
  useNotify,
  useRedirect,
  useTranslate,
} from "react-admin";
import { useDispatch } from "react-redux";
//import shared from "../DataProvider/methods/shared";

const RequestPasswordChangeButton = (props) => {
  const dispatch = useDispatch();
  const redirect = useRedirect();
  const notify = useNotify();
  const translate = useTranslate();

  const [isLoading, setIsLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const accept = async () => {
    const { record, resource } = props;

    setShowDialog(false);
    setIsLoading(true);

    let profile = localStorage.getItem("storevan_dealer_profile");

    profile = profile ? JSON.parse(profile) : {};

    dispatch(fetchStart());

    fetch(`${apiUrl}/request-password-change`, {
      method: "POST",
      body: JSON.stringify({ email: (profile as any).email }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(async (res) => {
        const response = await res.json();

        if (res.status < 200 || res.status >= 300) {
          notify("ra.message.error", "warning");
        } else {
          if (response.msg) {
            notify(`settings.${response.msg}`, "warning");
            notify("settings.request_password_user_not_found", "warning");
          } else {
            notify("settings.password_change_request_sent");
            redirect("/settings");
          }
        }
      })
      .catch((e) => {
        notify("ra.message.error", "warning");
      })
      .finally(() => {
        setShowDialog(false);
        setIsLoading(false);
        dispatch(fetchEnd());
      });
  };

  return (
    <span>
      <LoadingButton
        label="settings.request_password_change"
        isLoading={isLoading}
        onClick={() => setShowDialog(true)}
      />

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translate("settings.request_password_change")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translate("settings.request_password_change_alert")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} color="primary">
            {translate("ra.action.cancel")}
          </Button>
          <Button onClick={() => accept()} color="primary" autoFocus>
            {translate("ra.action.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
};

export default RequestPasswordChangeButton;
