import {  Lightbox as BaseLightbox } from "react-modal-image";
import {useState} from "react";
import * as React from "react";

export function LightBox({imageUrl, title}: {imageUrl: string,title: string}) {
    const[show, setShow] = useState(false)

    if(!show) {
        return  (
            <img src={imageUrl} alt={title} className="img-fluid" style={{cursor: "pointer"}} onClick={() => {
                setShow(true)}
            } />
        )
    }

    const closeLightbox = () => {
        setShow(false)
    };

    return (
       <BaseLightbox
           medium={imageUrl}
           large={imageUrl}
           alt={title}
           onClose={closeLightbox}
       />
   )
}