import CreateButton from "@main-components/buttons/create-button";
import DataGrid from "@main-components/data-grid";
import DateField from "@main-components/fields/date-field";
import TextField from "@main-components/fields/text-field";
import SelectInput from "@main-components/inputs/select-input";
import TextInput from "@main-components/inputs/text-input";
import List from "@main-components/list";
import ListWrapper from "@main-components/list-wrapper";
import Empty from "@main-components/list/components/empty";
import OverviewContext from "@main-components/list/utils/overview-context";
import useOverviewContext, {
  useOverviewState,
} from "@main-components/list/utils/use-overview-context";
import useResourceCount from "@misc/utils/useResourceCount";
import * as React from "react";
import { Filter, TopToolbar, useTranslate } from "react-admin";
import { Card } from "react-bootstrap";
import OrderCreateModal from "../create";
import OrderStatusField, { orderStatus } from "../shared/status-field";
const OrderFilter = (props) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput
        source="orderId"
        alwaysOn
        label={false}
        placeholder={translate("general.search")}
        InputProps={{
          startAdornment: (
            <span className="input-group-text h-100" id="search-addon">
              <i className="fal fa-search" aria-hidden="true"></i>
            </span>
          ),
        }}
      />

      <SelectInput
        source="status"
        choices={orderStatus}
        translateChoice={true}
        optionValue="id"
        alwaysOn
        label={false}
        inputProps={{
          placeholder: translate("resources.orders.messages.filter_by_status"),
        }}
      />
    </Filter>
  );
};

const OrderListActions = (props) => {
  return (
    <TopToolbar className="m-0 p-0 pt-1">
      <OrderFilter context="button" />
    </TopToolbar>
  );
};

const OrderMainActions = (props) => {
  const { setCreateActive } = useOverviewContext();
  return (
    <div>
      <CreateButton {...props} onClick={() => setCreateActive(true)} />
    </div>
  );
};

const OrderListView = (props) => {
  const { count } = useResourceCount("orders");
  const translate = useTranslate();

  const subHeader =
    count > 0
      ? `${count} ${translate("resources.orders.name", {
          smart_count: count === 1 ? 1 : 2,
        })}`
      : "";

  const { setCreateActive } = useOverviewContext();

  return (
    <>
      <List
        {...props}
        title={props.options.label}
        listSubHeader={subHeader}
        sort={{ field: "date", order: "desc" }}
        bulkActionButtons={false}
        filters={<OrderFilter />}
        exporter={false}
        actions={<OrderListActions />}
        mainActions={count > 0 && <OrderMainActions />}
        component={ListWrapper}
        hasCreate={true}
        perPage={50}
        empty={
          <Card className="w-100" style={{ height: 500 }}>
            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
              <Empty
                resourceOverride="orders"
                icon={
                  <div>
                    <img
                      src="/img/illustrations/orders.svg"
                      className="w-100"
                    />
                  </div>
                }
                actions={
                  <CreateButton
                    {...props}
                    onClick={() => {
                      setCreateActive(true);
                    }}
                  />
                }
              />
            </Card.Body>
          </Card>
        }
      >
        <DataGrid rowClick="show">
          <TextField source="orderId" />
          <DateField source="date" showTime />
          <TextField source="reference" />

          <OrderStatusField />
        </DataGrid>
      </List>
      <OrderCreateModal resource={props.resource} basePath={props.basePath} />
    </>
  );
};

const OrderList = (props) => {
  return (
    <OverviewContext.Provider
      value={{
        ...useOverviewState({
          openCreate: props.location.search === "?newOrder=true",
        }),
      }}
    >
      <OrderListView {...props} />
    </OverviewContext.Provider>
  );
};

export default OrderList;
