import * as React from "react";
import OrderList from "./list";
import OrderShow from "./show";

const resource = {
  list: OrderList,
  // create: OrderCreate,
  // edit: OrderEdit,
  show: OrderShow,
  icon: () => <i className="far fa-shopping-cart fa-fw"></i>,
};

export default resource;
