import DownloadCategoryList from "@main/downloadCategory/list";
import React from "react";

const resource = {
  list: DownloadCategoryList,
  //show: DownloadCategoryShow,
  icon: () => (
    <i className="far fa-cloud-download fa-fw" aria-hidden="true"></i>
  ),
};

export default resource;
