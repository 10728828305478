import Badge from "@main-components/badge";
import { SelectField } from "@main-components/fields/select-field";
import React, { FC } from "react";
import { SelectFieldProps, useTranslate } from "react-admin";

export type OrderStatus =
  "RECEIVED"
  | "PROCESSING"
  | "SCHEDULED"
  | "IN_BACK_ORDER"
  | "NEED_MORE_INFO"
  | "SENT";

export const orderStatus = [
  {
    name: "resources.orders.status.order_placed",
    id: "RECEIVED",
  },
  {
    name: "resources.orders.status.being_processed",
    id: "PROCESSING",
  },
  {
    name: "resources.orders.status.scheduled",
    id: "SCHEDULED",
  },
  {
    name: "resources.orders.status.in_backorder",
    id: "IN_BACK_ORDER",
  },
  {
    name: "resources.orders.status.needs_more_info",
    id: "NEED_MORE_INFO",
  },
  {
    name: "resources.orders.status.canceled",
    id: "CANCELED",
  },
  {
    name: "resources.orders.status.picked_up",
    id: "PICKED_UP",
  },
  {
    name: "resources.orders.status.sent",
    id: "SENT",
  },
];

interface StatusLabelProps {
  record?: any;
}

const statusColors = {
  SENT: "success",
  PICKED_UP: "success",
  IN_BACK_ORDER: "warning",
  NEED_MORE_INFO: "info",
  SCHEDULED: "primary",
  PROCESSING: "danger",
  RECEIVED: "danger",
  CANCELED: "danger"
};

const StatusLabel: FC<StatusLabelProps> = ({ record }) => {
  const translate = useTranslate();
  return (
    <Badge label={translate(record.name)} color={statusColors[record.id]} />
  );
};

const OrderStatusField: FC<Omit<
  Omit<SelectFieldProps, "source" | "choices">,
  "children"
>> = ({ record, label }) => {
  return record ? (
    <SelectField
      choices={orderStatus}
      source="status"
      translateChoice={false}
      record={record}
      label={label}
      optionText={<StatusLabel />}
    />
  ) : null;
};

export default OrderStatusField;

OrderStatusField.defaultProps = {
  addLabel: true,
  label: "Status",
};
