import PropTypes from "prop-types";
import { Record, RedirectionSideEffect } from "ra-core";
import * as React from "react";
import { FC, ReactElement, SyntheticEvent } from "react";
import { ButtonProps } from "..";
import DeleteWithConfirmButton from "./DeleteWithConfirmButton";
import DeleteWithUndoButton from "./DeleteWithUndoButton";

const DeleteButton: FC<DeleteButtonProps> = ({
  undoable,
  record,
  ...props
}) => {
  if (!record || record.id == null) {
    return null;
  }
  return undoable ? (
    <DeleteWithUndoButton record={record} {...props} />
  ) : (
    <DeleteWithConfirmButton record={record} {...props} />
  );
};

interface Props {
  basePath?: string;
  classes?: object;
  className?: string;
  icon?: ReactElement;
  label?: string;
  onClick?: (e: MouseEvent) => void;
  record?: Record;
  redirect?: RedirectionSideEffect;
  resource?: string;
  // May be injected by Toolbar
  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>;
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void;
  invalid?: boolean;
  pristine?: boolean;
  saving?: boolean;
  submitOnEnter?: boolean;
  undoable?: boolean;
}

export type DeleteButtonProps = Props & ButtonProps;

DeleteButton.propTypes = {
  basePath: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.any,
  // @ts-ignore
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  resource: PropTypes.string,
  undoable: PropTypes.bool,
  icon: PropTypes.element,
};

DeleteButton.defaultProps = {
  undoable: true,
  color: "danger",
};

export default DeleteButton;
