import Button from "@main-components/buttons";
import ImageField from "@main-components/fields/image-field";
import SimpleForm from "@main-components/forms/simple-form";
import ArrayInput from "@main-components/inputs/array-input";
import AutocompleteInput from "@main-components/inputs/autocomplete-input";
import DateInput from "@main-components/inputs/date-input";
import FileInput from "@main-components/inputs/file-input";
import TextInput from "@main-components/inputs/text-input";
import useOverviewContext from "@main-components/list/utils/use-overview-context";
import { useArrayInputStyles } from "@main/orders/create";
import * as React from "react";
import {
  ReferenceInput,
  required,
  SimpleFormIterator,
  useEditController,
  useRefresh,
  useTranslate,
} from "react-admin";
import { Modal } from "react-bootstrap";
import {RealizationCoverImageInput, transformRealizationData} from "../create";

const DetailsContent = () => {
  return (
    <>
      <TextInput source="title" validate={[required()]} />
      <DateInput source="finishingDate" validate={[required()]} />

      <ReferenceInput source="order" reference="orders">
        <AutocompleteInput optionText="orderId" />
      </ReferenceInput>
    </>
  );
};

const Description = () => {
  return (
    <>
      <TextInput source="description" as="textarea" />
    </>
  );
};
const PhotosContent = () => {
  const arrayInputClasses = useArrayInputStyles();

  return (
    <ArrayInput source="gallery" validate={[required()]}>
      <SimpleFormIterator
        addButton={
          <Button
            label="resources.realizations.actions.add_photo"
            variant="outline"
          />
        }
        removeButton={
          <Button
            label="ra.action.delete"
            variant="link"
            color="danger"
            style={{ color: "red" }}
          />
        }
        /* @ts-ignore */
        classes={{
          line: arrayInputClasses.line,
        }}
      >

        <FileInput
          source="url"
          label="general.picture"
          validate={[required()]}
          InputProps={{
            placeholder: "general.select_file",
          }}
        >
          <Preview  source="src"/>
        </FileInput>
        <TextInput
          source="description"
          label="general.description"
          validate={[required()]}
          multiline
          rows={3}
          as="textarea"
        />
        <RealizationCoverImageInput />
      </SimpleFormIterator>
    </ArrayInput>
  );
};

function Preview(props) {
  const isImage = props.record.rawFile ? true:  ["jpg", "JPG", "png", "PNG", "jpeg", "JPEG"].includes(
   ( props.record.src ).split(".").pop()
  );

  return isImage ? (
    <ImageField {...props} source="src" />
  ) : (
    <a href={props.record.src} target="_blank">
      {props.record.src}
    </a>
  );
}

export const RealizationEditForm = (props) => {
  const refresh = useRefresh();
  const editControllerProps = useEditController({
    ...props,
    transform: transformRealizationData,
    onSuccess: (order) => {
      props.handleClose();
      refresh();
    },
    undoable: false,
  });

  if (!editControllerProps.record) {
    return null;
  }

  const { context } = props;

  const renderFormContent = () => {
    switch (context) {
      case "details":
        return <DetailsContent />;
      case "photos":
        return <PhotosContent />;
      case "description":
        return <Description />;
      default:
        return null;
    }
  };
  return (
    <SimpleForm {...editControllerProps} redirect="list">
      {renderFormContent()}
    </SimpleForm>
  );
};

export const RealizationEditModal = ({ resource, basePath, id }) => {
  const { editActive, setEditActive, setEditContext, editContext } =
    useOverviewContext();

  const handleClose = () => {
    setEditActive(false);
    setEditContext(undefined);
  };
  const translate = useTranslate();

  return (
    <Modal show={editActive} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title as="h5">
          {translate("resources.realizations.titles.edit_realization")}
        </Modal.Title>
        <button
          type="button"
          className="btn-close"
          data-dismiss="modal"
          onClick={handleClose}
          aria-label="Close"
        ></button>
      </Modal.Header>

      <Modal.Body>
        <RealizationEditForm
          resource={resource}
          basePath={basePath}
          id={id}
          handleClose={handleClose}
          context={editContext}
        />
      </Modal.Body>
    </Modal>
  );
};
