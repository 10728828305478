import Button from "@main-components/buttons";
import CreateFormContainer from "@main-components/forms/create-form-container";
import SimpleForm from "@main-components/forms/simple-form";
import SimpleFormIterator from "@main-components/forms/simple-form/iterator";
import ArrayInput from "@main-components/inputs/array-input";
import AutocompleteInput from "@main-components/inputs/autocomplete-input";
import DateInput from "@main-components/inputs/date-input";
import SimpleFileInput from "@main-components/inputs/simple-file-input";
import TextInput from "@main-components/inputs/text-input";
import useOverviewContext from "@main-components/list/utils/use-overview-context";
import {useArrayInputStyles} from "@main/orders/create";
import * as React from "react";
import {ReferenceInput, required, useNotify, useRedirect, useTranslate,} from "react-admin";
import {Modal} from "react-bootstrap";
import BooleanInput from "@main-components/inputs/boolean-input";
import {useForm, useFormState} from "react-final-form";
import get from "lodash/get";


export const transformRealizationData = (data) => {
    data.gallery = data.gallery
        ? data.gallery.map((file) => {
            if (file.url.rawFile) {
                file.url = file.url.rawFile;
            }

            return file;
        })
        : [];

    return data;
};

const RealizationCreateForm = (props) => {
    const redirect = useRedirect();
    const notify = useNotify();
    const translate = useTranslate();
    const arrayInputClasses = useArrayInputStyles();

    return (
        <CreateFormContainer
            title="Create realization"
            {...props}
            component={"div"}
            transform={transformRealizationData}
            onSuccess={(newRecord) => {
                props.handleClose();
                notify("resources.realizations.messages.realization_created", "info");
                redirect("show", "/realizations", newRecord.data.id);
            }}
        >
            <SimpleForm redirect="list">
                <TextInput
                    source="title"
                    validate={[required()]}
                />
                <DateInput
                    source="finishingDate"
                    validate={[required()]}
                />

                <ReferenceInput
                    source="order"
                    reference="orders"
                >
                    <AutocompleteInput optionText="orderId"/>
                </ReferenceInput>
                <ArrayInput
                    source="gallery"
                    defaultValue={[{url: null, description: null, isCoverImage: true}]}
                    validate={[required()]}
                >
                    <SimpleFormIterator
                        addButton={
                            <Button
                                label="resources.realizations.actions.add_photo"
                                variant="outline"
                            />
                        }
                        removeButton={
                            <Button
                                label="ra.action.delete"
                                variant="link"
                                color="danger"
                                style={{color: "red"}}
                            />
                        }
                        /* @ts-ignore */
                        classes={{
                            line: arrayInputClasses.line,
                        }}
                    >
                        <SimpleFileInput
                            source="url"
                            label="general.picture"
                            validate={[required()]}
                            InputProps={{
                                placeholder: "general.select_file",
                            }}
                            accept="image/*"
                        />
                        <TextInput
                            source="description"
                            label="general.description"
                            validate={[required()]}
                            multiline
                            rows={3}
                            as="textarea"
                        />

                        <RealizationCoverImageInput/>
                    </SimpleFormIterator>
                </ArrayInput>
                <TextInput
                    source="description"
                    as="textarea"
                />
            </SimpleForm>
        </CreateFormContainer>
    );
};

export function RealizationCoverImageInput(props) {
    const {values} = useFormState();
    const {change} = useForm();

    const currentIndex = parseInt(props.source.substring(
        props.source.indexOf("[") + 1,
        props.source.lastIndexOf("]"))
    );

    return (
        <BooleanInput
            source={props.source + ".isCoverImage"}
            label="general.cover_image"
            onChange={(v) => {
                if (v) {
                    const newGallery = get(values, "realisations.gallery")?.map((e, index) => {
                        return {
                            ...e,
                            isCoverImage: index === currentIndex
                        }
                    })

                    change("gallery", newGallery)
                }
            }}
        />
    )
}

const RealizationCreateModal = ({resource, basePath}) => {
    const {createActive, setCreateActive} = useOverviewContext();

    const handleClose = () => {
        setCreateActive(false);
    };
    const translate = useTranslate();

    return (
        <Modal
            show={createActive}
            onHide={handleClose}
            size="lg"
        >
            <Modal.Header>
                <Modal.Title as="h5">
                    {translate("resources.realizations.titles.create_realization")}
                </Modal.Title>
                <button
                    type="button"
                    className="btn-close"
                    data-dismiss="modal"
                    onClick={handleClose}
                    aria-label="Close"
                ></button>
            </Modal.Header>

            <Modal.Body>
                <RealizationCreateForm
                    resource={resource}
                    basePath={basePath}
                    handleClose={handleClose}
                />
            </Modal.Body>
        </Modal>
    );
};

export default RealizationCreateModal;
