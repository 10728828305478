import * as React from "react";
import ProductsList from "./list";
import ProductsShow from "./show";

const resource = {
  list: ProductsList,
  show: ProductsShow,
  icon: () => <i className="far fa-cube fa-fw"></i>,
};

export default resource;
