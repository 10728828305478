import Card from "@main-components/card";
import EditIconButton from "@main-components/edit-icon-button";
import { DateField } from "@main-components/fields/date-field";
import TextField from "@main-components/fields/text-field";
import OverviewContext from "@main-components/list/utils/overview-context";
import { useOverviewState } from "@main-components/list/utils/use-overview-context";
import * as React from "react";
import { ReferenceField, useShowController, useTranslate } from "react-admin";
import { Table } from "react-bootstrap";
import { RealizationEditModal } from "../edit";
import {LightBox} from "@main-components/light-box";

const ShowLayout = (props) => {
  const { record } = useShowController(props);
  const translate = useTranslate();

  if (!record) return null;

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2 mb-3">
        <h1 id="react-admin-title">
          {record.title}
          {/*    <small className="h4 text-muted order-2 ml-2">{record.title}</small> */}
        </h1>
      </div>

      <div className="row">
        <div className="col-xs-12 col-lg-6 col-xl-4 mb-4">
          <Card className="mb-4">
            <Card.Header className="d-flex justify-content-between">
              {translate("resources.realizations.titles.project_information")}
              <EditIconButton context="details" />
            </Card.Header>
            <Card.Body>
              <Table className="m-0">
                <tbody>
                  <tr>
                    <th scope="row">
                      {translate("resources.realizations.fields.title")}
                    </th>
                    <td>
                      <TextField
                        source="title"
                        addLabel={false}
                        record={record}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      {translate("resources.realizations.fields.finishingDate")}
                    </th>
                    <td>
                      <DateField
                        source="finishingDate"
                        addLabel={false}
                        record={record}
                      />
                    </td>
                  </tr>

                  {record.order && (
                    <tr>
                      <th scope="row">
                        {translate("resources.realizations.fields.order")}
                      </th>
                      <td>
                        <ReferenceField
                          source="order"
                          reference="orders"
                          link="show"
                          record={record}
                          basePath="/orders"
                        >
                          <TextField source="orderId" />
                        </ReferenceField>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Card className="mb-4">
            <Card.Header className="d-flex justify-content-between">
              {translate("resources.realizations.titles.description")}
              <EditIconButton context="description" />
            </Card.Header>
            <Card.Body>
              {record.description
                ? record.description
                : translate("general.no")}
            </Card.Body>
          </Card>
        </div>
        <div className="col-xs-12 col-lg-6 col-xl-4 mb-4">
          {record && record.gallery && record.gallery.length > 0 && (
            <Card className="mb-4">
              <Card.Header className="d-flex justify-content-between">
                {translate("resources.realizations.titles.photos")}
                <EditIconButton context="photos" />
              </Card.Header>
              <Card.Body>
                <Table className="table no-hover m-0">
                  <tbody>
                    {record.gallery.map((g) => {
                      return (
                        <tr>
                          <td width="40%">
                            <LightBox imageUrl={g.url} title={g.description} />
                          {/*  <img src={g.url} alt="" className="img-fluid" />*/}
                          </td>
                          <td className="align-top">{g.description}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          )}
        </div>
      </div>
      <RealizationEditModal
        resource="realizations"
        basePath="/realizations"
        id={record.id}
      />
    </div>
  );
};
const RealizationShow = (props) => {
  const overviewInitialState = { ...useOverviewState() };

  return (
    <>
      <OverviewContext.Provider value={{ ...overviewInitialState }}>
        <ShowLayout {...props} />
      </OverviewContext.Provider>
    </>
  );
};
export default RealizationShow;
