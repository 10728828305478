import { MenuItem } from "@material-ui/core";
import { MenuItemProps } from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import classnames from "classnames";
import * as React from "react";
import { cloneElement, FunctionComponent, isValidElement } from "react";

const useStyles = makeStyles(
  (theme) => ({
    root: {
     whiteSpace: "pre-wrap"
    },
    selected: {
      fontWeight: 500,
    },
    suggestion: {
      display: "block",
      fontFamily: theme.typography.fontFamily,
      minHeight: 24,
    },
    suggestionText: { fontWeight: 300 },
    highlightedSuggestionText: { fontWeight: 500 },
  }),
  { name: "RaAutocompleteSuggestionItem" }
);

interface Props {
  suggestion: any;
  index: number;
  highlightedIndex: number;
  isSelected: boolean;
  filterValue: string;
  classes?: any;
  getSuggestionText: (suggestion: any) => string;
  getSuggestionDescriptionText?: (suggestion: any) => string;
}

const AutocompleteSuggestionItem: FunctionComponent<
  Props & MenuItemProps<"li", { button?: true }>
> = (props) => {
  const {
    suggestion,
    index,
    highlightedIndex,
    isSelected,
    filterValue,
    classes: classesOverride,
    getSuggestionText,
      getSuggestionDescriptionText,
    ...rest
  } = props;
  const classes = useStyles(props);
  const isHighlighted = highlightedIndex === index;
  const suggestionText = getSuggestionText(suggestion);
  const suggestionDescriptionText = getSuggestionDescriptionText ? getSuggestionDescriptionText(suggestion) : undefined;
  let matches;
  let parts;

  if (!isValidElement(suggestionText)) {
    matches = match(suggestionText, filterValue);
    parts = parse(suggestionText, matches);
  }

  return (
    <MenuItem
      key={suggestionText}
      selected={isHighlighted}
      className={classnames(classes.root, {
        [classes.selected]: isSelected,
      })}
      {...rest}
    >
      {isValidElement<{ filterValue }>(suggestionText) ? (
        cloneElement<{ filterValue }>(suggestionText, { filterValue })
      ) : (
        <div className={classes.suggestion}>
          {parts.map((part, index) => {
            return part.highlight ? (
              <span key={index} className={classes.highlightedSuggestionText}>
                {part.text}
              </span>
            ) : (
              <strong key={index} className={classes.suggestionText}>
                {part.text}
              </strong>
            );
          })}
            {
                suggestionDescriptionText && (
                    <div>
                        <span style={{fontSize: 12}} className={"text-muted font-weight-bold"}>{suggestionDescriptionText}</span>
                    </div>
                )
            }
        </div>
      )}
    </MenuItem>
  );
};

export default AutocompleteSuggestionItem;
