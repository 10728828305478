import Button from "@main-components/buttons";
import RichTextField from "@main-components/fields/rich-text-field";
import DateTimeUtils from "@misc/utils/date-time-utils";
import * as React from "react";
import { useShowController } from "react-admin";

const NewsShow = (props) => {
  const { record } = useShowController(props);
  if (!record) return null;

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2 mb-3">
        <h1>{record.title}</h1>
      </div>
      <div className="row">
        <div className="col-xs-12 col-xl-6">
          <div className="card">
            <div className="card-body">
              <article className="blog-post">
                {record && record.featuredImage ? (
                  <img
                    src={record.featuredImage}
                    alt=""
                    className="featured-image"
                  />
                ) : (
                  <img
                    src="/img/example.jpg"
                    alt=""
                    className="featured-image"
                  />
                )}
                <span className="date d-block mb-5">
                  <b>
                    {DateTimeUtils.format(record.date, "DD/MM/YYYY")} door
                    StoreVan
                  </b>
                </span>

                <RichTextField record={record} source="content" />
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat( auto-fit, minmax(250px, 100px) )",
                  }}
                  className="mt-5"
                >
                  {record.gallery &&
                    record.gallery.map((image) => {
                      return (
                        <div className="mr-2">
                          <img
                            src={image.url}
                            alt={image.url}
                            style={{ width: "100%" }}
                          />
                        </div>
                      );
                    })}
                </div>
                <Button
                  className="mt-3"
                  link
                  to="/news"
                  variant="outline"
                  block={false}
                  label="resources.news.actions.go_to_overview"
                />
              </article>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsShow;
