const TextUtils = {
  htmlSubstring: (s, n) => {
    var m,
      r = /<([^>\s]*)[^>]*>/g,
      stack = [],
      lasti = 0,
      result = "";

    //for each tag, while we don't have enough characters
    while ((m = r.exec(s)) && n) {
      //get the text substring between the last tag and this one
      var temp = s.substring(lasti, m.index).substr(0, n);
      //append to the result and count the number of characters added
      result += temp;
      n -= temp.length;
      lasti = r.lastIndex;

      if (n) {
        result += m[0];
        if (m[1].indexOf("/") === 0) {
          //if this is a closing tag, than pop the stack (does not account for bad html)
          stack.pop();
        } else if (m[1].lastIndexOf("/") !== m[1].length - 1) {
          //if this is not a self closing tag than push it in the stack
          /* @ts-ignore */
          stack.push(m[1]);
        }
      }
    }

    //add the remainder of the string, if needed (there are no more tags in here)
    result += s.substr(lasti, n);

    //fix the unclosed tags
    while (stack.length) {
      result += "</" + stack.pop() + ">";
    }

    return result;
  },
  numberToPrice: (number) => {
    return new Intl.NumberFormat('nl-BE', {
      style: 'currency',
      currency: 'EUR'
    }).format(parseFloat(number));
  }
};

export default TextUtils;
