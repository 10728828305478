import Layout from "@main-components/layout";
import Dashboard from "@main/dashboard";
import downloadCategory from "@main/downloadCategory";
import downloads from "@main/downloads";
import LoginPage from "@main/login";
import news from "@main/news";
import orders from "@main/orders";
import products from "@main/products";
import realizations from "@main/realizations";
import AuthProvider from "@misc/auth-provider";
import customRoutes from "@misc/custom-routes";
import DataProvider from "@misc/data-provider";
import TranslationProvider from "@misc/translation-provider";
import theme from "@theme";
import {createBrowserHistory as createHistory} from "history";
import * as React from "react";
import {Admin, Resource} from "react-admin";

export const apiUrl = process.env.REACT_APP_API_URL;

function App() {
    const history = createHistory({
        basename: process.env.PUBLIC_URL,
    });

    console.log(apiUrl)
    return (
        <React.Fragment>
            <Admin
                theme={theme}
                history={history}
                authProvider={AuthProvider(`${apiUrl}/login`)}
                dataProvider={DataProvider(apiUrl)}
                loginPage={LoginPage}
                layout={Layout}
                //catchAll={NotFound}
                dashboard={Dashboard}
                i18nProvider={TranslationProvider}
                customRoutes={customRoutes}
            >
                {(permissions) => {
                    return [
                        <Resource
                            options={{label: "resources.products.name"}}
                            name="products"
                            {...products}
                        />,
                        <Resource
                            options={{label: "resources.orders.name"}}
                            name="orders"
                            {...orders}
                        />,

                        <Resource // THIS IS DOWNLOAD ITEM
                            options={{label: "resources.downloads.name"}}
                            name="downloadCategory"
                            {...downloads}
                        />,
                        <Resource // THIS IS DOWNLOAD CATEGORY
                            options={{label: "resources.downloads.name"}}
                            name="downloads"
                            match
                            {...downloadCategory}
                        />,

                        <Resource
                            options={{label: "resources.news.name"}}
                            name="news"
                            {...news}
                        />,

                        <Resource
                            options={{label: "resources.realizations.name"}}
                            name="realizations"
                            {...realizations}
                        />,

                        <Resource name="settings"/>,
                        <Resource name="dashboard-orders"/>,
                        <Resource name="dashboard-realizations"/>,
                        <Resource name="dashboard-news"/>,
                        <Resource name="company-info"/>,
                        <Resource name="profile"/>,
                        <Resource name="order-history"/>,
                        <Resource name="website"/>,

                    ];
                }}
            </Admin>
        </React.Fragment>
    );
}

export default App;
