import SimpleForm from "@main-components/forms/simple-form";
import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from '@material-ui/core/Tab';
import {TabPanelProps} from "@material-ui/lab";
import {BrandingSection} from "@main/public-website/components/sections/branding-section";
import {HeaderSection} from "@main/public-website/components/sections/header-section";
import {ContactPersonSection} from "@main/public-website/components/sections/contact-person-section";
import {CompanyInfoSection} from "@main/public-website/components/sections/company-info-section";
import {RealizationsSection} from "@main/public-website/components/sections/realizations-section";
import {NotificationSection} from "@main/public-website/components/sections/notification-section";
import {OpeningHoursSection} from "@main/public-website/components/sections/opening-hours-section";
import {useTranslate} from "react-admin";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export function WebsiteForm(props) {

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const translate = useTranslate()

    const tabStyles = {
        background: "white",
        textTransform: "none",
        fontWeight: "bold"
    } as any
    return (
        <SimpleForm
            {...props.controllerProps}
            undoable={false}
        >

            <Tabs
                value={value}
                onChange={(event, value) => {
                    handleChange(event as any, value)
                }}
                style={{
                    background: "white",
                }}
            >
                <Tab
                    label={`${translate("resources.website.sections.branding")}`} {...a11yProps(0)}
                    style={tabStyles}
                />
                <Tab
                    label={`${translate("resources.website.sections.header")}`}  {...a11yProps(1)}
                    style={tabStyles}
                />
                <Tab
                    label={`${translate("resources.website.sections.company_info")}`}  {...a11yProps(2)}
                    style={tabStyles}
                />
                <Tab
                    label={`${translate("resources.website.sections.contact_person")}`}  {...a11yProps(3)}
                    style={tabStyles}
                />
                <Tab
                    label={`${translate("resources.website.sections.opening_hours")}`}  {...a11yProps(4)}
                    style={tabStyles}
                />
                <Tab
                    label={`${translate("resources.website.sections.notification")}`}  {...a11yProps(5)}
                    style={tabStyles}
                />
                <Tab
                    label={`${translate("resources.website.sections.realizations")}`}  {...a11yProps(6)}
                    style={tabStyles}
                />
            </Tabs>


            <CustomTabPanel
                value={value as any}
                id={0 as any}
            >

                <BrandingSection/>


            </CustomTabPanel>
            <CustomTabPanel
                value={value as any}
                id={1 as any}
            >
                <HeaderSection/>
            </CustomTabPanel>
            <CustomTabPanel
                value={value as any}
                id={2 as any}
            >
                <CompanyInfoSection/>
            </CustomTabPanel>

            <CustomTabPanel
                value={value as any}
                id={3 as any}
            >
                <ContactPersonSection/>
            </CustomTabPanel>

            <CustomTabPanel
                value={value as any}
                id={4 as any}
            >
                <OpeningHoursSection/>
            </CustomTabPanel>
            <CustomTabPanel
                value={value as any}
                id={5 as any}
            >
                <NotificationSection/>
            </CustomTabPanel>
            <CustomTabPanel
                value={value as any}
                id={6 as any}
            >
                <RealizationsSection/>
            </CustomTabPanel>
        </SimpleForm>
    )
}

function CustomTabPanel(props: TabPanelProps) {
    const {children, value, id, ...other} = props;

    return (
        // @ts-ignore
        <div
            role="tabpanel"
            hidden={value !== id}
            id={`simple-tabpanel-${id}`}
            aria-labelledby={`simple-tab-${id}`}
            style={{
                marginTop: 20
            }}
            {...other}
        >
            {value === id && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}