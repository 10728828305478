import ShowButton from "@main-components/buttons/show-button";
import Card from "@main-components/card";
import RichTextField from "@main-components/fields/rich-text-field";
import GridList from "@main-components/grid-list";
import List from "@main-components/list";
import Empty from "@main-components/list/components/empty";
import DateTimeUtils from "@misc/utils/date-time-utils";
import TextUtils from "@misc/utils/text";
import * as React from "react";
import { useTranslate } from "react-admin";
import Masonry from "react-masonry-css";

function NewsCardItem(data: any) {
  return (
    <div className="col-sm-6 col-lg-4 mb-4 w-100">
      <div className="card">
        <div
          className="card-img-top"
          style={{
            background: `url(${
              data.featuredImage ? data.featuredImage : "img/example.jpg"
            })`,
            backgroundSize: "cover",
            height: "291px",
            backgroundRepeat: "no-repeat",
            width: "100%",
            backgroundPosition: "center",
          }}
        >
          {/*  {data.featuredImage ? (
            <img src={data.featuredImage} alt="" />
          ) : (
            <img src="img/example.jpg" alt="" />
          )} */}
        </div>
        <div className="card-img-overlay text-right">
          <span className="badge bg-dark mt-3 mr-3">
            {/* <i className="fal fa-clock"></i> 3 minuten geleden */}
            <i className="fal fa-calendar mr-1"></i>{" "}
            {DateTimeUtils.format(data.date, "DD/MM/YYYY ")} om {""}
            {DateTimeUtils.format(data.date, "HHumm")}
          </span>
        </div>

        <div className="card-body" style={{ zIndex: 9999 }}>
          <h5 className="card-title">{data.title}</h5>
          <div className="mb-3">
            <RichTextField
              record={{
                ...data,
                content: TextUtils.htmlSubstring(data.content, 100),
              }}
              source="content"
            />
          </div>
          <ShowButton
            resource="news"
            label="resources.news.actions.read_message"
            record={data}
            basePath="news"
            alignIcon={"right"}
          />
        </div>
      </div>
    </div>
  );
}

const Container = ({ children }) => (
  <Masonry
    breakpointCols={{
      default: 4,
      1366: 3,
      1100: 3,
      700: 2,
      500: 1,
    }}
    className="row"
    columnClassName=""
  >
    {children}
  </Masonry>
);

const NewsList = (props) => {
  const translate = useTranslate();

  return (
    <>
      <List
        {...props}
        title={props.options.label}
        sort={{ field: "createdAt", order: "desc" }}
        bulkActionButtons={false}
        exporter={false}
        actions={false}
        component={"div"}
        hasCreate={true}
        empty={
          <Card className="w-100" style={{ height: 500 }}>
            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
              <Empty
                resourceOverride="news"
                icon={
                  <div>
                    <img src="/img/illustrations/news.svg" className="w-100" />
                  </div>
                }
                hideActions
                subtitle=" "
              />
            </Card.Body>
          </Card>
        }
      >
        <GridList
          width="lg"
          cols={{ xs: 1, sm: 2, md: 3, lg: 4 }}
          cellHeight={300}
          ContainerComponent={Container}
          renderGridItem={(data: any) => <NewsCardItem {...data} />}
        />
      </List>
    </>
  );
};
export default NewsList;
