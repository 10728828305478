import Card from "@main-components/card";
import DataGrid from "@main-components/data-grid";
import TextField from "@main-components/fields/text-field";
import TextInput from "@main-components/inputs/text-input";
import List from "@main-components/list";
import ListWrapper from "@main-components/list-wrapper";
import Empty from "@main-components/list/components/empty";
import * as React from "react";
import { Filter, FunctionField, useReference, useTranslate } from "react-admin";
import { useSelector } from "react-redux";

const CatNameText = ({ id }) => {
  const { loaded, referenceRecord } = useReference({
    id: id,
    reference: "downloads",
  });

  if (!loaded) {
    return null;
  }

  return <>{referenceRecord?.name}</>;
};

const CountSubtitle = ({ id }) => {
  const translate = useTranslate();
  const resource = useSelector(
    (state: any) => state.admin.resources.downloadCategory
  );

  const { loaded, referenceRecord } = useReference({
    id: id,
    reference: "downloads",
  });

  if (!loaded) {
    return null;
  }

  return (
    <p className="font-weight-bold mb-0">
      {translate("resources.downloads.messages.downloads_in_category", {
        total: resource.list.total || 0,
        cateName: referenceRecord?.name,
      })}
    </p>
  );
};

const DownloadCategoryShow = (props) => {
  const translate = useTranslate();

  if (!props.match.params.id) {
    return null;
  }

  const ListFilters = (props) => (
    <Filter {...props}>
      <TextInput
        source="q"
        alwaysOn
        label={false}
        placeholder={translate("general.search")}
        InputProps={{
          startAdornment: (
            <span className="input-group-text h-100" id="product-search-addon">
              <i className="fal fa-search" aria-hidden="true"></i>
            </span>
          ),
        }}
      />
    </Filter>
  );

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2 mb-3">
        <h1>
          <CatNameText id={props.match.params.id} />{" "}
          <small className="h4 text-muted">
            {translate(props.options.label, { smart_count: 2 })}
          </small>
        </h1>
      </div>

      <CountSubtitle id={props.match.params.id} />
      <List
        {...props}
        resource="downloadCategory"
        basePath="/downloadCategory"
        title={null}
        sort={{ field: "createdAt", order: "desc" }}
        bulkActionButtons={false}
        exporter={false}
        filter={{
          categoryId: props.match.params.id,
        }}
        component={ListWrapper}
        filters={<ListFilters />}
        hasCreate={true}
        actions={<div />}
        empty={
          <Card className="w-100" style={{ height: 500 }}>
            <Card.Body className="d-flex flex-column align-items-center justify-content-center">
              <Empty resourceOverride="downloads" hideActions subtitle=" " />
            </Card.Body>
          </Card>
        }

        //filters={<DownloadFilter />}
      >
        <DataGrid>
          <TextField source="title" />
          <TextField source="description" />
          <FunctionField
            source="type"
            render={(record) => {
              var fileExt =
                record && record.file ? record.file.split(".").pop() : "";

              return fileExt;
            }}
          />

          <FunctionField
            render={(record) => {
              return (
                <>
                  <i className="fal fa-download" aria-hidden="true"></i>{" "}
                  <a
                    href={record.file}
                    target="__BLANK"
                    title={translate("general.download_file")}
                  >
                    {translate("general.download")}
                  </a>
                </>
              ); //<UrlField source="attachment" />
            }}
          />
        </DataGrid>
      </List>
    </>
  );
};

export default DownloadCategoryShow;
