import AppBarTitle from "@main-components/app-bar-title";
import RichTextField from "@main-components/fields/rich-text-field";
import TextField from "@main-components/fields/text-field";
import * as React from "react";
import {FunctionField, useShowController, useTranslate} from "react-admin";
import { Card, Table } from "react-bootstrap";
import TextUtils from "@misc/utils/text";
import NumberField from "@main-components/fields/number-field";
import BooleanField from "@main-components/fields/boolean-field";
import ImageField from "@main-components/fields/image-field";
import Badge from "@main-components/badge";

const ProductShow = (props) => {
  const { record } = useShowController(props);
  const translate = useTranslate();
  if (!record) return null;
  return (
    <div>
      <AppBarTitle title={record.name} />

      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2 mb-3">
        <h1 id="react-admin-title">
          <small className="h4 text-muted order-2 ml-2">{record.code}</small>
        </h1>{" "}
      </div>

      <div className="row">
        <div className="col-xs-12 col-lg-6 col-xl-4 mb-4">
          <Card className="mb-4">
            <Card.Header>
              {translate("resources.products.titles.basic_info")}
            </Card.Header>
            <Card.Body>
              <Table className="m-0">
                <tbody>
                  <tr>
                    <th scope="row">
                      {translate("resources.products.fields.name")}
                    </th>
                    <td>
                      <TextField
                        source="name"
                        addLabel={false}
                        record={record}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      {translate("resources.products.fields.code")}
                    </th>
                    <td>
                      <TextField
                        source="code"
                        addLabel={false}
                        record={record}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      {translate("resources.products.fields.sellingPrice")}
                    </th>
                    <td>
                      <FunctionField source="sellingPrice" record={record} render={(record) => {
                        return TextUtils.numberToPrice(record.sellingPrice)
                      }} />
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      {translate("resources.products.fields.description")}
                    </th>
                    <td>
                      <RichTextField
                        source="description"
                        addLabel={false}
                        record={record}
                      />
                    </td>
                  </tr>

                  <tr>
                    <th scope="row">
                      {translate("resources.products.fields.stock")}
                    </th>
                    <td>
                      <NumberField
                          source="stock"
                          addLabel={false}
                          record={record}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      {translate("resources.products.fields.inBackOrder")}
                    </th>
                    <td>
                      <FunctionField
                          record={record}
                          source="inBackOrder"
                          render={(record) => {
                            return record.inBackOrder ? (
                                <Badge
                                    label={translate("resources.products.fields.inBackOrder")}
                                    color={"warning"}
                                />
                            ) : (
                                <Badge
                                    label={`${translate("resources.products.fields.inStock")}`}
                                    color={"success"}
                                />
                            )
                          }}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
        <div className="col-xs-12 col-lg-6 col-xl-4 mb-4">
          <Card className="mb-4">
            <Card.Header>
              {translate("resources.products.titles.image")}
            </Card.Header>
            <Card.Body>
              <div className={"align-items-xxl-center justify-content-center d-flex"}>
                <ImageField
                    record={record}
                    source={"imageUrl"}
                    fullWidth
                />
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};
export default ProductShow;
