import Button from "@main-components/buttons";
import LoadingButton from "@main-components/buttons/loading-button";
import CardActions from "@material-ui/core/CardActions";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useLogin, useNotify, useSafeSetState, useTranslate } from "ra-core";
import * as React from "react";
import { FunctionComponent } from "react";
import { Form as RBForm, FormGroup } from "react-bootstrap";
import { Field, Form } from "react-final-form";

interface Props {
  redirectTo?: string;
}

interface FormData {
  username: string;
  password: string;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    formContainer: {
      width: "100%",
      maxWidth: "330px",
      padding: "15px",
      margin: "auto",
    },
    form: {
      //padding: "0 1em 1em 1em",
    },
    input: {
      marginTop: "1em",
    },
    button: {
      width: "100%",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: "RaLoginForm" }
);

const Input = ({
  meta: { touched, error }, // eslint-disable-line react/prop-types
  input: inputProps, // eslint-disable-line react/prop-types
  ...props
}) => {
  const translate = useTranslate();

  return (
    <FormGroup>
      <RBForm.Control size="md" {...inputProps} {...props} fullWidth />
      {!!(touched && error) && (
        <RBForm.Control.Feedback type="invalid" style={{ display: "block" }}>
          {translate(error)}
        </RBForm.Control.Feedback>
      )}
    </FormGroup>
  );
};

const LoginForm: FunctionComponent<Props> = (props) => {
  const { redirectTo } = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);

  const validate = (values: FormData) => {
    const errors: {
      username: string | undefined;
      password: string | undefined;
    } = {
      username: undefined,
      password: undefined,
    };

    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    return errors;
  };

  const submit = (values) => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
            ? "ra.auth.sign_in_error"
            : error.message,
          "error"
        );
      });
  };

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({ handleSubmit }) => (
        <div className={`form-signin text-center ${classes.formContainer}`}>
          <img src="/img/storevan-logo.png" className="logo mb-3" />

          <form onSubmit={handleSubmit} noValidate>
            <h1 className="h3 mb-3">{translate("ra.auth.login_title")}</h1>

            <div className={classes.form}>
              <div className={classes.input}>
                <Field
                  //autoFocus
                  id="username"
                  name="username"
                  component={Input}
                  label={translate("ra.auth.username")}
                  disabled={loading}
                  placeholder={translate("ra.auth.username")}
                />
              </div>
              <div className={classes.input}>
                <Field
                  id="password"
                  name="password"
                  component={Input}
                  label={translate("ra.auth.password")}
                  type="password"
                  disabled={loading}
                  placeholder={translate("ra.auth.password")}
                  autoComplete="current-password"
                />
              </div>
            </div>
            <CardActions style={{ padding: 0, flexDirection: "column" }}>
              <LoadingButton
                type="submit"
                isLoading={loading}
                label="ra.auth.sign_in"
                block
                style={{ margin: 0 }}
              />

              <Button
                label={"ra.auth.forgot_my_password"}
                block
                link
                variant="link"
                to="forgot-password"
                style={{ marginBottom: 5 }}
              />
            </CardActions>

            <div className="checkbox mt-3">
              <label>
                <input type="checkbox" value="remember-me"></input> Gegevens
                onthouden{" "}
              </label>
            </div>

            <p className="mt-5 mb-3 text-muted">
              &copy; 2014-2020 StoreVan Vlaanderen
            </p>
          </form>
        </div>
      )}
    />
  );
};

export default LoginForm;
