import ForgotPassword from "@main/forgot-password";
import ShowProfile from "@main/profile/show";
import ResetPassword from "@main/reset-password";
import Settings from "@main/settings";
import * as React from "react";
import {Route} from "react-router-dom";
import PublicWebsite from "@main/public-website";

export default [
    <Route
        exact
        path="/settings"
        render={() => <Settings/>}
    />,
    <Route
        exact
        path="/forgot-password"
        component={ForgotPassword}
        noLayout
    />,
    <Route
        exact
        path="/reset-password/:token"
        component={ResetPassword}
        noLayout
    />,
    <Route
        exact
        path="/profile"
        component={ShowProfile}
    />,
    <Route
        exact
        path="/website"
        render={() => <PublicWebsite/>}
    />,
];
