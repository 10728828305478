import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup, {FormGroupProps} from "@material-ui/core/FormGroup";
import Switch, {SwitchProps} from "@material-ui/core/Switch";
import PropTypes from "prop-types";
import {FieldTitle, InputProps, useInput, useTranslate} from "ra-core";
import * as React from "react";
import {FunctionComponent, useCallback} from "react";
import InputPropTypes from "../InputPropTypes";
import sanitizeRestProps from "../sanitizeRestProps";

const BooleanInput: FunctionComponent<InputProps<SwitchProps> &
    Omit<FormGroupProps, "defaultValue" | "onChange" | "onBlur" | "onFocus">> = ({
    format,
    label,
    fullWidth,
    helperText,
    onBlur,
    onChange,
    onFocus,
    options,
    disabled,
    parse,
    resource,
    source,
    validate,
    ...rest
}) => {
    const {
        id,
        input: {onChange: finalFormOnChange, type, value, ...inputProps},
        isRequired,
        meta: {error, touched},
    } = useInput({
        format,
        onBlur,
        onChange,
        onFocus,
        parse,
        resource,
        source,
        type: "checkbox",
        validate,
        ...rest,
    });

    const handleChange = useCallback(
        (event, value) => {
            finalFormOnChange(value);
        },
        [finalFormOnChange]
    );
    const hasError = !!(touched && error);
    const isValid = !!(touched && !error);

    const translate = useTranslate();
    return (
        <FormGroup {...sanitizeRestProps(rest)}>
            <FormControlLabel
                control={
                    <Switch
                        id={id}
                        color="primary"
                        onChange={handleChange}
                        {...inputProps}
                        {...options}
                        disabled={disabled}
                    />
                }
                label={
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                }
            />

            {/*  {hasError && (
                <Form.Control.Feedback
                    type="invalid"
                    style={{display: "block"}}
                >
                    {translate(error)}
                </Form.Control.Feedback>
            )}

            {!hasError && helperText && (
                <Form.Text muted>{translate(helperText)}</Form.Text>
            )}*/}
        </FormGroup>
    );
};

/* @ts-ignore */
BooleanInput.propTypes = {
    ...InputPropTypes,
    // @ts-ignore
    options: PropTypes.shape(Switch.propTypes),
    disabled: PropTypes.bool,
};

BooleanInput.defaultProps = {
    options: {},
};

export default BooleanInput;
