//@ts-nocheck
import {
  TablePaginationBaseProps,
  Theme,
  Toolbar,
  useMediaQuery,
} from "@material-ui/core";
import PropTypes from "prop-types";
import {
  ComponentPropType,
  sanitizeListRestProps,
  useListPaginationContext,
  useTranslate,
} from "ra-core";
import * as React from "react";
import { FC, ReactElement, useCallback, useMemo } from "react";
import UltimatePagination from "react-ultimate-pagination-bootstrap-4";
import DefaultPaginationActions from "./components/pagination-actions";
import DefaultPaginationLimit from "./components/pagination-limit";
const emptyArray = [];

function PaginationBar({
  rowsPerPage,
  page,
  onPrev,
  onNext,
  changePage,
  count,
  totalPages,
  handlePerPageChange
}) {
  return (
    <nav className="mt-3 d-flex">
      <div className="mr-2">
        <UltimatePagination
            currentPage={page + 1}
            totalPages={totalPages}
            onChange={changePage}
        />
      </div>
      <div>
        <select
            className="form-select"
            aria-label="Per page"
            onChange={handlePerPageChange}
        >
          <option >Per page</option>
          <option selected={rowsPerPage === 10} value="10">10</option>
          <option selected={rowsPerPage === 25} value="25">25</option>
          <option selected={rowsPerPage === 50} value="50">50</option>
          <option selected={rowsPerPage === 100} value="100">100</option>
          <option selected={rowsPerPage === 250} value="250">250</option>
        </select>
      </div>
    </nav>
  );
}

const Pagination: FC<PaginationProps> = (props) => {
  const { rowsPerPageOptions, actions, limit, ...rest } = props;
  const {
    loading,
    page,
    perPage,
    total,
    setPage,
    setPerPage,
  } = useListPaginationContext(props);
  const translate = useTranslate();
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const totalPages = useMemo(() => {
    return Math.ceil(total / perPage) || 1;
  }, [perPage, total]);

  /**
   * Warning: material-ui's page is 0-based
   */

  const handlePageChange = useCallback(
    (page) => {
      //  event && event.stopPropagation();
      if (page < 0 || page > totalPages) {
        throw new Error(
          translate("ra.navigation.page_out_of_boundaries", {
            page: page + 1,
          })
        );
      }
      //setPage(page + 1);

      setPage(page);
    },
    [totalPages, setPage, translate]
  );

  const handlePerPageChange = useCallback(
    (event) => {
      setPerPage(event.target.value);
    },
    [setPerPage]
  );

  const labelDisplayedRows = useCallback(
    ({ from, to, count }) =>
      translate("ra.navigation.page_range_info", {
        offsetBegin: from,
        offsetEnd: to,
        total: count,
      }),
    [translate]
  );

  // Avoid rendering TablePagination if "page" value is invalid
  if (total === null || total === 0 || page < 1 || page > totalPages) {
    return loading ? <Toolbar variant="dense" /> : limit;
  }

  if (isSmall) {
    return (
      <PaginationBar
        count={total}
        totalPages={totalPages}
        page={page - 1}
        onNext={() => handlePageChange(page + 1)}
        onPrev={() => handlePageChange(page - 1)}
        changePage={(toPage) => handlePageChange(toPage)}
        rowsPerPage={perPage}
        handlePerPageChange={handlePerPageChange}
        {...sanitizeListRestProps(rest)}
      />
    );
  }

  return (
    <PaginationBar
      count={total}
      totalPages={totalPages}
      page={page - 1}
      onNext={() => handlePageChange(page + 1)}
      onPrev={() => handlePageChange(page - 1)}
      changePage={(toPage) => handlePageChange(toPage)}
      rowsPerPage={perPage}
      handlePerPageChange={handlePerPageChange}
      {...sanitizeListRestProps(rest)}
    />
  );
};

Pagination.propTypes = {
  actions: ComponentPropType,
  limit: PropTypes.element,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
};

Pagination.defaultProps = {
  actions: DefaultPaginationActions,
  limit: <DefaultPaginationLimit />,
  rowsPerPageOptions: [5, 10, 25],
};

export interface PaginationProps extends TablePaginationBaseProps {
  rowsPerPageOptions?: number[];
  actions?: FC;
  limit?: ReactElement;
}

export default React.memo(Pagination);
