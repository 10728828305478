import Card from "@main-components/card";
import ImageInput from "@main-components/inputs/image-input";
import React from "react";
import ImageField from "@main-components/fields/image-field";
import {useTranslate} from "react-admin";

export function HeaderSection() {
    const translate = useTranslate()

    return (
        <Card className="mb-5">
            {/*<Card.Header>
                <i
                    className="fas fa-question-circle mr-2"
                    aria-hidden="true"
                />
                {translate("resources.website.sections.header")}
            </Card.Header>*/}
            <Card.Body>
                <div className={"row"}>
                    <div className={"col-12 col-lg-6"}>
                        <ImageInput
                            source="header.headerImage"
                            label="resources.website.fields.header.headerImage"
                            accept="image/jpeg,image/png"
                        >
                            <ImageField source="src"/>
                        </ImageInput>
                    </div>
                </div>
            </Card.Body>
        </Card>
    )
}