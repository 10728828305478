//@ts-nocheck
import {PropTypes as MuiPropTypes} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import Quill, {QuillOptionsStatic} from "quill";
import {FieldTitle, useInput} from "ra-core";
import React, {ComponentProps, FunctionComponent, useCallback, useEffect, useRef,} from "react";
import {Form} from "react-bootstrap";
import InputHelperText from "../InputHelperText";
import styles from "./styles";
import {useTranslate} from "react-admin";

const useStyles = makeStyles(styles, {name: "RichTextInput"});

interface Props {
    label?: string | false;
    options?: QuillOptionsStatic;
    source: string;
    toolbar?:
        | boolean
        | string[]
        | Array<any>[]
        | string
        | {
        container: string | string[] | Array<any>[];
        handlers?: Record<string, Function>;
    };
    fullWidth?: boolean;
    configureQuill?: (instance: Quill) => void;
    helperText?: ComponentProps<typeof InputHelperText>["helperText"];
    record?: Record<any, any>;
    resource?: string;
    variant?: string;
    margin?: MuiPropTypes.Margin;

    [key: string]: any;
}

const RichTextInput: FunctionComponent<Props> = (props) => {
    const {
        options = {}, // Quill editor options
        toolbar = true,
        fullWidth = true,
        classes: classesOverride,
        configureQuill,
        helperText,
        label,
        source,
        resource,
        variant,
        margin = "dense",
        ...rest
    } = props;
    const classes = useStyles(props);
    const quillInstance = useRef<Quill>();
    const divRef = useRef<HTMLDivElement>();
    const editor = useRef<HTMLElement>();

    const {
        id,
        isRequired,
        input: {value, onChange},
        meta: {touched, error},
    } = useInput({source, ...rest});

    const lastValueChange = useRef(value);
    const translate = useTranslate()

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onTextChange = useCallback(
        debounce(() => {
            const value =
                editor.current.innerHTML === "<p><br></p>"
                    ? ""
                    : editor.current.innerHTML;
            lastValueChange.current = value;
            onChange(value);
        }, 500),
        [onChange]
    );

    useEffect(() => {
        quillInstance.current = new Quill(divRef.current, {
            modules: {toolbar, clipboard: {matchVisual: false}},
            theme: "snow",
            ...options,
        });
        quillInstance.current.root.style['min-height'] = '200px';
        if (configureQuill) {
            configureQuill(quillInstance.current);
        }

        quillInstance.current.setContents(
            quillInstance.current.clipboard.convert(value)
        );

        editor.current = divRef.current.querySelector(".ql-editor");
        quillInstance.current.on("text-change", onTextChange);

        return () => {
            quillInstance.current.off("text-change", onTextChange);
            onTextChange.cancel();
            quillInstance.current = null;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (lastValueChange.current !== value) {
            const selection = quillInstance.current.getSelection();
            quillInstance.current.setContents(
                quillInstance.current.clipboard.convert(value)
            );
            if (selection && quillInstance.current.hasFocus()) {
                quillInstance.current.setSelection(selection);
            }
        }
    }, [value]);

    const hasError = !!(touched && error);
    const isValid = !!(touched && !error);

    return (
        <Form.Group
            className="ra-rich-text-input"
        >
            {label !== "" && label !== false && (
                <Form.Label>
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                </Form.Label>
            )}
            <div
                data-testid="quill"
                ref={divRef}
                className={variant}
            />
            {hasError && (
                <Form.Control.Feedback
                    type="invalid"
                    style={{display: "block"}}
                >
                    {translate(error)}
                </Form.Control.Feedback>
            )}

            {!hasError && helperText && <Form.Text muted>{helperText}</Form.Text>}
        </Form.Group>
    );
};

RichTextInput.propTypes = {
    label: PropTypes.string,
    options: PropTypes.object,
    source: PropTypes.string,
    fullWidth: PropTypes.bool,
    configureQuill: PropTypes.func,
};

export default RichTextInput;
