import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import { useTranslate } from "react-admin";
import { default as RBButton } from "react-bootstrap/Button";

const LoadingButton = ({
  label,
  isLoading,
  onClick,
  block,
  variant = "primary",
  ...rest
}: {
  isLoading?: boolean;
  [prop: string]: any;
}) => {
  const translate = useTranslate();

  return (
    <RBButton
      disabled={isLoading}
      onClick={onClick && onClick}
      variant={variant}
      {...rest}
      style={{ ...(rest.style || {}), display: block ? null : "inline-flex" }}
      block={block}
    >
      {translate(label)}
      {isLoading && (
        <CircularProgress
          size={20}
          style={{ marginLeft: 10, color: "black" }}
        />
      )}
    </RBButton>
  );
};

export default LoadingButton;
