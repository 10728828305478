import DataGrid from "@main-components/data-grid";
import TextField from "@main-components/fields/text-field";
import TextInput from "@main-components/inputs/text-input";
import List from "@main-components/list";
import ListWrapper from "@main-components/list-wrapper";
import useResourceCount from "@misc/utils/useResourceCount";
import * as React from "react";
import { Filter, useTranslate, FunctionField } from "react-admin";
import TextUtils from "@misc/utils/text";
import NumberField from "@main-components/fields/number-field";
import BooleanField from "@main-components/fields/boolean-field";
import Badge from "@main-components/badge";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    centered: {
        textAlign: 'center',
    },
});


const ProductList = (props) => {
  const { count } = useResourceCount("products", {source: "exact",   deleted: false});
  const translate = useTranslate();
    const classes = useStyles();
  const subHeader =
    count > 0
      ? `${count} ${translate("resources.products.name", {
          smart_count: count === 1 ? 1 : 2,
        })}`
      : "";

  const ListFilters = (props) => (
    <Filter {...props}>
      <TextInput
        source="q"
        alwaysOn
        label={false}
        placeholder={translate("general.search")}
        InputProps={{
          startAdornment: (
            <span className="input-group-text h-100" id="product-search-addon">
              <i className="fal fa-search" aria-hidden="true"></i>
            </span>
          ),
        }}
      />
    </Filter>
  );
  return (
    <>
      <List
        {...props}
        listSubHeader={subHeader}
        title={props.options.label}
        bulkActionButtons={false}
        exporter={false}
        component={ListWrapper}
        filters={<ListFilters />}
        filter={{
            source: "exact",
            deleted: false
        }}
      >
        <DataGrid rowClick="show">
            <FunctionField
                source="imageUrl"
                render={(record) => {
                    return <img
                        src={record.imageUrl}
                        style={{width: 100, height: 100}}
                        alt=""
                    />
                }}
            />
          <TextField source="code" />
          <TextField source="name" />
          <TextField source="description" />
            <FunctionField source="sellingPrice" render={(record) => {
                return TextUtils.numberToPrice(record.sellingPrice)
            }} />
            <NumberField source="stock"
             headerClassName={classes.centered}
             cellClassName={classes.centered}
            />
            <FunctionField
                source="status"
                render={(record) => {
                    return record.inBackOrder ? (
                        <Badge
                            label={translate("resources.products.fields.inBackOrder")}
                            color={"warning"}
                        />
                    ) : (
                        <Badge
                            label={`${translate("resources.products.fields.inStock")}`}
                            color={"success"}
                        />
                    )
                }}
            />
        </DataGrid>
      </List>
    </>
  );
};

export default ProductList;
