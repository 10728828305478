import { Theme, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "ra-core";
import * as React from "react";
import { Children, cloneElement } from "react";
import {
  ClassesOverride,
  LoadingIndicator,
  UserMenu as DefaultUserMenu,
} from "react-admin";
import { Navbar, NavbarProps } from "react-bootstrap";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(
  (theme) => ({
    toolbar: {
      marginLeft: "auto",
      paddingRight: 24,
    },
    menuButton: {
      marginLeft: "0.5em",
      marginRight: "0.5em",
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(["transform"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: "rotate(0deg)",
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(["transform"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: "rotate(180deg)",
    },
    title: {
      flex: 1,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
  }),
  { name: "RaAppBar" }
);

const AppBar = (props: AppBarProps): JSX.Element => {
  const {
    children,
    classes: classesOverride,
    className,
    variant = "light",
    logout,
    open,
    title,
    userMenu,
    ...rest
  } = props;
  const classes = useStyles(props);
  const dispatch = useDispatch();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down("xs")
  );
  const translate = useTranslate();

  return (
    <header className="navbar sticky-top navbar-expand-lg navbar-light  fixed-top flex-md-nowrap p-0">
      {/* <MuiAppBar className={className} color={color} {...rest}> */}
      <Navbar.Brand href="/" className="d-flex align-items-center">
        <img className="logo" src="/img/storevan-logo.png" alt="storevan" />
      </Navbar.Brand>

      {Children.count(children) === 0 ? (
        <Typography
          variant="h6"
          color="inherit"
          className={classes.title}
          id="react-admin-title"
        />
      ) : (
        children
      )}

      <div className="ml-md-auto h-100 d-flex align-items-center">
        <LoadingIndicator />
        {typeof userMenu === "boolean"
          ? userMenu === true
            ? cloneElement(<DefaultUserMenu />, { logout })
            : null
          : /* @ts-ignore */
            cloneElement(userMenu, { logout })}
        {/*   </Toolbar> */}
      </div>
    </header>
  );
};

AppBar.defaultProps = {
  userMenu: <DefaultUserMenu />,
};

export interface AppBarProps extends Omit<NavbarProps, "title" | "classes"> {
  classes?: ClassesOverride<typeof useStyles>;
  logout?: JSX.Element;
  open?: boolean;
  title?: string | JSX.Element;
  userMenu?: JSX.Element | boolean;
}

export default AppBar;
