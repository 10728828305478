import PropTypes from "prop-types";
import {
  ListContextProvider,
  useCheckMinimumRequiredProps,
  useListController,
} from "ra-core";
import * as React from "react";
import { FC } from "react";
import { ListProps, TitlePropType } from "react-admin";
import ListView from "./components/list-view";

type Props = ListProps & {
  mainActions?: React.ReactElement | false;
};

const List: FC<Props> = (props) => {
  useCheckMinimumRequiredProps("List", ["children"], props);
  const controllerProps = useListController(props);

  return (
    <ListContextProvider value={controllerProps}>
      <ListView {...props} {...controllerProps} />
    </ListContextProvider>
  );
};

List.propTypes = {
  // the props you can change
  // @ts-ignore-line
  actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
  aside: PropTypes.element,
  // @ts-ignore-line
  bulkActionButtons: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  children: PropTypes.element,
  classes: PropTypes.object,
  className: PropTypes.string,
  filter: PropTypes.object,
  filterDefaultValues: PropTypes.object,
  filters: PropTypes.element,
  // @ts-ignore-line
  pagination: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
  perPage: PropTypes.number.isRequired,
  //@ts-ignore-line
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  title: TitlePropType,
  // the props managed by react-admin
  authProvider: PropTypes.func,
  hasCreate: PropTypes.bool.isRequired,
  hasEdit: PropTypes.bool.isRequired,
  hasList: PropTypes.bool.isRequired,
  hasShow: PropTypes.bool.isRequired,
  location: PropTypes.any,
  match: PropTypes.any,
  path: PropTypes.string,
  resource: PropTypes.string.isRequired,
};

List.defaultProps = {
  filter: {},
  perPage: 10,
};

export default List;

/* import { List } from "react-admin";

export default List;

export interface ListProps {}
 */
