import Card from "@main-components/card";
import SimpleForm from "@main-components/forms/simple-form";
import CheckboxGroupInput from "@main-components/inputs/checkbox-group-input";
import LocaleSwitcher from "@main-components/locale-switcher";
import {makeStyles} from "@material-ui/core/styles";
import * as React from "react";
import {useAuthenticated, useEditController, useGetIdentity, useLocale, useTranslate,} from "react-admin";
import RequestPasswordChangeButton from "./components/request-password-change-button";

const useStyles = makeStyles({
    label: {width: "10em", display: "inline-block"},
    button: {margin: "1em"},
});

const SettingsContent = ({id}) => {
    const translate = useTranslate();
    const classes = useStyles();
    const locale = useLocale();

    const {record, save, saving, loading} = useEditController({
        id: id,
        resource: "settings",
        basePath: "/settings",
        undoable: false,
    });

    if (record && record.id) {
        delete record.id;
    }

    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4 pb-2 mb-3">
                <h1>{translate("general.configuration")}</h1>
            </div>

            <div
                className="row"
                data-masonry='{"percentPosition": true }'
            >
                <div className="col-xs-12 col-md-6 col-xl-4 mb-4">
                    <Card className="mb-4">
                        <Card.Header>
                            <i
                                className="far fa-cogs mr-2"
                                aria-hidden="true"
                            ></i>{" "}
                            {translate("settings.titles.general_settings")}
                        </Card.Header>
                        <Card.Body>
                            <SimpleForm
                                save={(data) => {
                                    save({
                                        emailOrderStatusChanged:
                                            data.notifications.indexOf("emailOrderStatusChanged") >
                                            -1,
                                    });
                                }}
                                record={record}
                                saving={saving}
                                undoable={false}
                                initialValues={{
                                    language: locale,
                                    ...(record ? record : {}),
                                }}
                            >
                                <LocaleSwitcher/>
                                <CheckboxGroupInput
                                    label={false}
                                    source="notifications"
                                    choices={[
                                        {
                                            id: "emailOrderStatusChanged",
                                            name: "settings.order_status_changed",
                                        },
                                    ]}
                                    translateChoices={true}
                                    className="mb-0"
                                />
                            </SimpleForm>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-xs-12 col-md-6 col-xl-4 mb-4">
                    <Card className="mb-4">
                        <Card.Header>
                            <i className="far fa-cog mr-2"></i>{" "}
                            {translate("settings.request_password_change")}
                        </Card.Header>
                        <Card.Body>
                            <RequestPasswordChangeButton/>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
    );
};

const Settings = () => {
    useAuthenticated();

    const {loaded, loading, identity} = useGetIdentity();

    if (loading) return null

    if (!loaded || !identity) return null;

    return <SettingsContent id={identity.id}/>;
};

export default Settings;
